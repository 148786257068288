import React from 'react';
import Filters from './filters';
import AddResource from './addResource';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import Toolbar from './toolbar';

class NewTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tablename: '',
      card_style: '',
      showFilters: false,
      showResources: false,
      filters: [],
      tableID: '',
      filterError: false,
    }

    this.handleEventChange = this.handleEventChange.bind(this);
    this.goBack = this.goBack.bind(this);
    this.validateNext = this.validateNext.bind(this);
  }

  handleEventChange = (event) => {
    const { target } = event;
    const { value } = target;
    const { name } = target;
    this.setState({
      [name]: value,
    });
  }

  goBack() {
    window.history.back();
  }

  checkColumn = () => {
    const filters = this.state.filters;
    let filterCheck = true;

    for (let i = 0; i < filters.length; i +=  1) {
      const values = filters[i].options;
      const nameArray = [];
      for (let k = 0; k < values.length; k += 1) {
        const name = filters[i].options[k].name;
        if (nameArray.indexOf(name) > -1) {
          filterCheck = false;
        } else {
          nameArray.push(name);
        }
      }
    }

    return filterCheck;
  }

  createTable = async () => {
    const postBody = {
      id: this.props.id,
      name: this.state.tablename,
      cardStyle: '',
      columns: this.state.filters,
    }

    const uniqueFilters = await this.checkColumn();
    if (uniqueFilters) {
      try {
        const table = await axios.post('/newTable', postBody);
        window.location = `/tables/${table.data.table.id}`;
        // this.setState({ showResources: true, showFilers: false, tableID: table.data.table.id });
      } catch (err) {
        console.log(err);
      }
    } else {
      this.setState({ filterError: true })
    }
  }

  async validateNext(filters) {
    await this.setState({ filters })
    if (this.state.tablename !== '') {
      this.createTable();
    }
  }

  setFilterGroups = (filters) => {
    this.setState({ filters });
  }


  activateFilters = () => {
    if (this.state.showFilters) {
      this.setState({ showFilters: false });
    } else {
      this.setState({ showFilters: true })
    }
  }

  render() {
    let tableSetup;
    let filterError;


    const filters = (
      <Filters filters={this.setFilterGroups} advance={this.validateNext} showFilters={this.activateFilters} />
    )

    if (this.state.filterError) {
      filterError = (
        <div className="error">
          <p>Filter options must all have unique names</p>
        </div>
      )
    }

    if (!this.state.showResources) {
      tableSetup = (
        <div className="new-table__grid">
          <div className="new-table__column">

          </div>
          <div className="new-table__column">
            <div className="new-table__column-inner">
              <h2>Title + Filters</h2>
              <p>Your title will become the name of your HubDB table, so you'll want to be descriptive but brief—and once this is created it sticks and can't be changed.</p>
              <div className="new-table__grid-input">
                <label htmlFor="tablename">Table Name<span className="required">*</span></label>
                <input type="text" name="tablename" placeholder="Your Resource Project" required onChange={this.handleEventChange} />
              </div>
              {filters}
              {!this.state.showFilters ? <button className="cta-btn cta-btn--fill" onClick={() => this.validateNext([])} >Next</button> : ''}
              {filterError}
            </div>
          </div>
          <div className="new-table__column new-table__column--help">
            <div className="helper-text">
              <p className="intro-text"><i className="far fa-lightbulb" />Pro Tip</p>
              <p>Keep your title to less than 100 characters and must start with a letter and avoid using a special characters.</p>
            </div>
          </div>
        </div>
      )
    } else {
      tableSetup = (
        <AddResource id={this.state.tableID} name={this.state.tablename} cardStyle={this.state.card_style} filters={this.state.filters} tableID={this.state.tableID} />
      )
    }

    return (
      <div className="new-table">
        <Toolbar completedStep={false} tableName={this.state.tablename} unpublish={this.props.unpublish} />
        {tableSetup}
      </div>
    )
  }
}

export default NewTable;
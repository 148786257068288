import React from 'react';
import axios from 'axios';
import TableSidebar from './tableSidebar';
import TableCard from './tableCard';
import NewTable from './newTable';
import AddResource from './addResource';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link, Redirect } from 'react-router-dom';

class TableDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTables: [],
      createTable: false,
      editTableView: false,
      activeTableName: '',
      activeTableFilters: '',
      activeTableId: '',
      payment: false,
    }

    this.displayActiveTables = this.displayActiveTables.bind(this);
  }


  displayActiveTables = (tables) => {
    if (tables.length > 0) {
      this.setState({ activeTables: tables });
    }
  }

  checkIfPaid = async () => {
    try {
      const check = await axios.post('/userDetails', { _id: localStorage.getItem('user') });
      if (check.data.contact.properties.df_payment_status) {
        if (check.data.contact.properties.df_payment_status.value === 'Paid') {
          return true;
        }
      }
    } catch (err) {
      return false;
    }
  }

  async componentDidMount() {
    const paid = await this.checkIfPaid();
    if (paid) {
      this.setState({ payment: true });
    }
  }
  
  render() {
    let loggedInState;

    const payment = (
      <div className="dashboard__authorize-payment">
        <p>In order to access this app, go to our <a href="https://app.hubspot.com/payments/XZVTJWWYXd7V">payment page</a> to begin.</p>
        <br />
        <p>If you have paid, and are not able to connect to HubSpot, please give a minute or two to process your payment and refresh the page.</p>
        <p>Or you can reach out to <a href="https://www.boldstack.io/support">support</a>.</p>
      </div>
    )

    const authorizationButton = (
      <div className="dashboard__authorize-button">
        <p>Next up: Let's make it official by connecting your HubSpot portal.</p>
        <button className="cta-btn hubspot-connect"><img src="https://f.hubspotusercontent30.net/hubfs/8093087/icons/Vector.png" alt="hubspot" /><a href="/install">Connect to HubSpot</a></button>
        <p>You will be prompted to sign into your HubSpot account to connect the BoldStack App.</p>
        <p>If you ever change your mind, <a href="https://knowledge.hubspot.com/integrations/how-do-i-uninstall-an-integration-in-hubspot" target="_blank" rel="noreferrer">easily disconnect in your HubSpot portal</a>.</p>
      </div>
    )
  
    if (this.props.userData.authenticated) {
      loggedInState = (
        <div className="dashboard__table-inner">
          <TableSidebar tables={this.props.userData.tables || []} id={this.props.userData.id} username={this.props.userData.username} activeTables={this.displayActiveTables} />
          <div className="dashboard__table-display">
            <div className="dashboard__intro">
              <h1>My HubDB Resource Projects</h1>
              <p>Creating a database to house all of your resources in HubSpot's HubDB has never been easier.</p>
              <p>boldstack resources is designed to walk you through creating a resource center that can be added to most HubSpot CMS pages. Sort, curate and re-order with ease.</p>
            </div>
            <div className="dashboard__table-grid">
              <div className="table-card table-card--new-table">
                <Link className="table-card__inner" to="/tables/new">
                  <div className="table-card__main">
                    <div className="table-card__new-table">
                      <FontAwesomeIcon icon={faPlus} />
                    </div>
                    <span className="table-card__new-table-title">Create a new resource project</span>
                  </div>
                </Link>
              </div>
              {/* <div className="table-card table-card--new-table">
                <Link className="table-card__inner" to="/import">
                  <div className="table-card__main">
                    <div className="table-card__new-table">
                      <FontAwesomeIcon icon={faPlus} />
                    </div>
                    <span className="table-card__new-table-title">Use an existing table</span>
                  </div>
                </Link>
              </div> */}
              {this.state.activeTables.map((item, i) => (
                <TableCard key={ item.id } tableName={ item.label } tableId={ item.id } tableCount={ item.rowCount } clickFunction={this.displayTable} />
              ))}
            </div>
          </div>
        </div>
      )
    } else {
      loggedInState = (
        <div className="dashboard__authorize">
          <h2>Welcome {this.props.userData.username}</h2>
          <p>We're happy you've chosen to add us to your tech stack!</p>
          {this.state.payment ? authorizationButton : payment}
        </div>
      );
    }

    const createNewTable = (
      <Redirect to="/tables/new" />
    )

    return (
      <div className="dashboard__table">
        {/* {this.state.createTable ? createNewTable : loggedInState} */}
        { loggedInState }
      </div>
    )
  }
}

export default TableDashboard;

import React from 'react';
import ResourceCard from './resourceCard';
import axios from 'axios';

class ResourceModal extends React.Component {
  state = {}

  componentDidMount() {
    const inputData = this.props.data;
    this.getValues(inputData);
  }

  onFileChange = event => { 
    this.setState({ selectedFile: event.target.files[0] });
  };

  getValues = (data) => {
    Object.entries(data).forEach(([key, value]) => {
      if (value.id) {
        this.setState({ [key]: value.id });
      } else {
        this.setState({ [key]: value });
      }
    })
  }

  handleEventChange = (event) => {
    const { target } = event;
    const { value } = target;
    const { name } = target;
    this.setState({
      [name]: value,
    });
  }

  closeModal = () => {
    this.props.onClose();
  }

  submitChanges = async () => {
    try {
      const formData = new FormData(); 

      // Update the formData object 
      if (this.state.selectedFile) {
        formData.append('image', this.state.selectedFile);
      }

      if (this.props.filters.length > 0) {
        const column = [];
        for (let i = 0; i < this.props.filters.length; i += 1) {
          const name = this.props.filters[i].columnName;
          const customColumnObject = { value: this.state[name], name };
          column.push(customColumnObject);
        }
        formData.append('customColumn', JSON.stringify(column));
      }

      formData.append('name', this.state.Title);
      formData.append('description', this.state.Description);
      formData.append('link', this.state.Link);
      formData.append('alt', this.state.Image_alt_text);
      formData.append('tableID', this.props.tableId);
      formData.append('rowID', this.props.rowId)
      formData.append('id', localStorage.getItem('user'));
  
  
      const update = await axios.post('/editRow', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      });
      // console.log(update.status);
      this.props.onClose(true);
    } catch (err) {
      this.setState({ errorState: true });
    }
  }

  render() {
    let filterGroups;

    if (this.props.filters && this.props.filters.length > 0) {
      filterGroups = (
        this.props.filters.map((item, i) => (
          <div className="resources-sidebar__input-group" key={item.columnName}>
            <label className="resources-sidebar__input-group-label">{item.columnName}</label>
            <select name={item.columnName} value={this.state[item.columnName]} onChange={this.handleEventChange}>
              <option>Make a Selection</option>
              {item.options.map((element, i) => {
              return <option key={i} value={i + 1}>{element.name}</option>
              })}
            </select>
          </div>
        ))
      )
    }

    return (
      <div className="resources-modal__viewport">
        <div className="resources-modal">
          <div className="resources-modal__header">
            <div className="resources-modal__header-name">Edit: {this.props.data.Title || 'Row'}</div>
            <div className="resources-modal__header-buttons">
              <button className="cta-btn--stroke" onClick={this.closeModal} >Cancel</button>
              <button className="cta-btn" onClick={this.submitChanges} >Save</button>
            </div>
          </div>
          <div className="resources-modal__inner">
            <div className="resources-modal__form">
              <div className="resources-modal__form-input">
                <div className="resources-modal__form-input-group">
                  <label>Resource Name</label>
                  <input name="Title" type="text" value={this.state.Title || ''} onChange={this.handleEventChange} />
                </div>
                <div className="resources-modal__form-input-group">
                  <label>Resource Description</label>
                  <textarea name="Description" value={this.state.Description|| ''} onChange={this.handleEventChange} rows="4"/>
                </div>
                {filterGroups}
                <div className="resources-modal__form-input-group">
                  <label>Resource Url</label>
                  <input name="Link" type="text" placeholder="Resource url" value={this.state.Link || ''} onChange={this.handleEventChange} />
                </div>
                <div className="resources-modal__form-input-group">
                  <label>Resource Image</label>
                  <input type="file" onChange={this.onFileChange} ref={this.fileRef} />
                </div>
                <div className="resources-modal__form-input-group">
                  <label>Resource Image Alt Text</label>
                  <input name="Image_alt_text" type="text" placeholder="" value={this.state.Image_alt_text || ''} onChange={this.handleEventChange} />
                </div>
              </div>
            </div>
            <div className="resources-modal__display">
              <div className="resources-modal__display-title">Preview</div>
              <div className="resources-modal__display-preview">
                <ResourceCard 
                  key={ this.props.id}
                  title={ this.props.data.Title || '' }
                  description={ this.props.data.Description || '' }
                  imgSrc={ this.props.data.Image || '' }
                  link={ this.props.data.Link || ''}
                  edit={ () => { return false } }
                  delete={ () => { return false } }
                  removeActions
                />
              </div>
            </div>
          </div>
        </div>
        <div className="resources-modal__spacer" />
      </div>
    )
  }
}

export default ResourceModal;
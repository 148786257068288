import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTimes } from '@fortawesome/free-solid-svg-icons';

class ResourceCard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let filters;
    let actions;
    let deleted;

    if (!this.props.removeActions) {
      actions = (
        <div className="resource-card__actions">
          <div className="resource-card__edit">
            <FontAwesomeIcon icon={faPen} />
          </div>
        </div>
      );

      deleted = (
        <button className="resource-card__delete" onClick={ this.props.delete }>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      )
    }

    if (this.props.filters) {
      filters = this.props.filters.map(filter => (<span className="resource-card__filter"><span>{ filter.label }</span><span>{ filter.value }</span></span>));
    }

    return (
      <div className="resource-card">
        <div className="resource-card__inner" onClick={ this.props.edit }>
          <div className="resource-card__main">
            <span className="resource-card__title">{ this.props.title }</span>
            <span className="resource-card__description">{ this.props.description }</span>
            <img className="resource-card__image" src={ this.props.imgSrc.url } alt={ this.props.imgAlt } />
            { filters }
            <span className="resource-card__link">{ this.props.link }</span>
          </div>
          {actions}
        </div>
        {deleted}
      </div>
    );
  }
}

export default ResourceCard;
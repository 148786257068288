import React from 'react';
import queryString from 'query-string'
import Header from './header';
import axios from 'axios';
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';
import Logo from './logo';
import TableDashboard from './tableDashboard';
import AddResource from './addResource';
import NewTable from './newTable';
import Toolbar from './toolbar';
import ExistingTable from './existingTable';
import ImportTable from './importTable';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: '',
      user: '',
      username: '',
      firstname: '',
      lastname: '',
      password: '',
      authenticated: false,
      apiURL: 'http://localhost:8080',
      isError: false,
      errorMessage: '',
      loginDisplay: true,
      signupDisplay: false,
      loggedIn: false,
      publish: false,
      forgotPassword: false,
      activeTable: '',
      activeTableRows: [],
      username_helper: '',
      password_helper: '',
      password_helper2: '',
      password_reset: '',
      domain: '',
      hub_id: '',
      response: '',
    }

    // Login & Authentication
    this.userLogin = this.userLogin.bind(this);
    this.signup = this.signup.bind(this);
    this.checkLoginStatus = this.checkLoginStatus.bind(this);
    this.logout = this.logout.bind(this);

    // Utility Functions
    this.handleEventChange = this.handleEventChange.bind(this);
    this.toggleLogin = this.toggleLogin.bind(this);
  }

  // Login & Authentication
  userLogin = async (event) => {
    event.preventDefault();
    const user = this.state.username;
    const date = Date.now();
    const password = this.state.password;

    const postBody = {
      user,
      date,
      password,
    }
    try {
      const getUser = await axios.post('/getUser', postBody);
      localStorage.setItem('user', getUser.data.id);
      await this.setState({ userData: getUser.data, isError: false, errorMessage: '', loggedIn: true });

      if (this.state.userData.authenticated) {
        this.setState({
          authenticated: true
        });
        this.getPortalData();
      }
    } catch (err) {
      this.setState({ isError: true,
        errorMessage: 'Invalid username or password', forgotPassword: true
      });
    }
  }

  signup = async (event) => {
    event.preventDefault();
    if (this.state.firstname !== '' && this.state.lastname !== '' && this.state.username !== '' && this.state.password !== '' && this.state.username_helper === '' && this.state.password_helper === '' && this.state.password_helper2 === '') {
      const user = this.state.username;
      const date = Date.now();
      const firstname = this.state.firstname;
      const lastname = this.state.lastname;
      const password = this.state.password;
  
      const postBody = {
        user,
        date,
        firstname,
        lastname,
        password,
      };
  
      try {
        const newUser = await axios.post(`/createUser`, postBody);
        
        if (newUser.data.user) {
          localStorage.setItem('user', newUser.data.user._id);
          this.setState({ userData: newUser.data.user, isError: false, errorMessage: '', loggedIn: true });
          // console.log(this.state.userData);
        } else {
          this.setState({ isError: true, errorMessage: 'Pick a different username' })
        }
      } catch (err) {
        this.setState({ isError: true });
      }
    } else {
      this.setState({ required_text: 'All fields are required' });
    }
  }

  checkLoginStatus = async () => {
    const date = Date.now();
    const id = localStorage.getItem('user');

    const postBody = {
      id,
      date,
    }

    try {
      const checkUser = await axios.post(`/checkStatus`, postBody);
      await this.setState({ userData: checkUser.data, isError: false, errorMessage: '' });
      localStorage.setItem('user', checkUser.data.id);

      if (this.state.userData.authenticated) {
        this.setState({ authenticated: true });
      }
    } catch (err) {
      this.setState({ isError: true,
        userData: '',
        errorMessage: 'Please refresh page or try again later',
      });
      localStorage.setItem('user', '');
    }
  }

  validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  // Utility Functions
  handleEventChange = (event) => {
    const { target } = event;
    const { value } = target;
    const { name } = target;
    this.setState({
      [name]: value,
    });

    if (event.target.name === 'username' && !this.state.loginDisplay) {
      const value = event.target.value;
      const validated = this.validateEmail(value);
      if (!validated) {
        this.setState({ username_helper: 'Please enter your email address used to login to HubSpot'})
      } else {
        this.setState({ username_helper: ''})
      }
    }

    if (event.target.name === 'password' && !this.state.loginDisplay) {
      const value = event.target.value;
      if (value.length < 6) {
        this.setState({ password_helper: 'Password must be at least 6 characters' });
      } else {
        this.setState({ password_helper: '' });
      }
    }

    if (event.target.name === 'password_validate' && !this.state.loginDisplay) {
      const value = event.target.value;
      if (value !== this.state.password && this.state.password !== '') {
        this.setState({ password_helper2: 'Passwords must match' });
      } else {
        this.setState({ password_helper2: '' });
      }
    }

    if (event.target.name === 'password_reset') {
      const value = event.target.value;
      if (value.length < 6) {
        this.setState({ password_helper: 'Password must be at least 6 characters' });
      } else {
        this.setState({ password_helper: '' });
      }
    }

    if (event.target.name === 'password_reset_validate') {
      const value = event.target.value;
      if (value !== this.state.password_reset && this.state.password_reset !== '') {
        this.setState({ password_helper2: 'Passwords must match', validatedPassword: false });
      } else {
        this.setState({ password_helper2: '', validatedPassword: true });
      }
    }
  }

  toggleLogin() {
    if (this.state.loginDisplay) {
      this.setState({ 
        loginDisplay: false,
        signupDisplay: true
      });
    } else {
      this.setState({ 
        loginDisplay: true,
        signupDisplay: false
      });
    }
  }

  logout = (logout) => {
    if (logout) {
      localStorage.setItem('user', '');
      this.setState({ loggedIn: false });
      window.location = '/'
    } 
  }

  changePassword = async (event) => {
    event.preventDefault();
    const parsed = queryString.parse(window.location.search);
    if (parsed.code) {
      const reset = parsed.code;
      const date = Date.now();
      const password = this.state.password_reset;
  
      const postBody = {
        reset,
        date,
        password,
      }
      try {
        const send = await axios.post('/reset', postBody);
        this.setState({ response: 'Password changed sucessfully. Redirecting to login page...' });
        setTimeout(function display(){
          window.location = '/';
        }, 3000);
      } catch (err) {
        this.setState({ isError: true, errorMessage: 'Username not found' });
      }
    }
  }

  // React Functions
  async componentDidMount() {
    let query = window.location.search;
    if (query.indexOf('?authorized=true') > -1) {
      query = query.split('&');
      const queryData = await this.formatQuery(query);
  
      const bodyToSend = {
        token: queryData.token,
        refresh: queryData.refresh,
        expires: queryData.expires,
        user: localStorage.getItem('user'),
      }
      const updateUser = await axios.post(`/updateUser`, bodyToSend);

      if (!updateUser.data.err) {
        await this.setState({ authenticated: true, isError: false, errorMessage: '', loggedIn: true });
      } else {
        this.setState({ isError: true });
      }
    }

    if (localStorage.getItem('user')) {
      this.setState({ loggedIn: true, publish: false });
      await this.checkLoginStatus();
      this.getPortalData();
    }
  }

  getPortalData = async () => {
    try {
      const hubspot = await axios.post('/portal', {id: localStorage.getItem('user')});
      this.setState({ domain: hubspot.data.portal.domain, hub_id: hubspot.data.portal.id });
    } catch (err) {
      console.log(err);
      this.setState({ domain: '', hub_id: '' });
    }
  }

  formatQuery = async (data) => {
    const queryData = {}
    for (let i = 0; i < data.length; i += 1) {
      let queryString = data[i];
      queryString = queryString.split('=');
      if (queryString[0] === 'token') {
        // this.setState({ token: queryString[1] });
        queryData.token = queryString[1];
      } else if (queryString[0] === 'refresh') {
        // this.setState({ refresh: queryString[1] });
        queryData.refresh = queryString[1];
      } else if (queryString[0] === 'expires') {
        // this.setState({ expires: queryString[1] });
        queryData.expires = queryString[1];
      }
    }
    return queryData;
  }

  publish = (table, rows) => {
    localStorage.setItem(table, 'true');
    this.setState({ publish: true, activeTable: table, activeTableRows: rows });
  }

  hidePublish = () => {
    this.setState({ publish: false, activeTable: '', activeTableRows: [] });
  }

  receiveRowUpdate = (rows) => {
    this.setState({ activeTableRows: rows, publish: false });
  }

  switchPortal = async () => {
    const update = await axios.post('/removeAuth', { id: localStorage.getItem('user') });
    // console.log(update.data);
    window.location.reload();
    // this.setState({ authenticated: false });
  }


  render() {
    let errorState;
    let forgotPassword;

    if (this.state.forgotPassword) {
      forgotPassword = (
        <p><a href="/reset-password">Forgot Password?</a></p>
      )
    }


    if (this.state.isError) {
      errorState = (
        <div>
          <p>{ this.state.errorMessage}</p>
          {forgotPassword}
        </div>
      )
    }

    const login = (
      <div className="dashboard__login">
        <Logo />
        <h2>Sign in</h2>
        <form onSubmit={ this.userLogin } className="form__login">
          <label htmlFor="username">
            Username
          </label>
          <input id="username" name="username" type="text" onChange={ this.handleEventChange } autoComplete="username" />
          <label htmlFor="password">Password</label>
          <input name="password" type="password" onChange={this.handleEventChange} autoComplete="current-password" />
          <input className="cta-btn cta-btn--medium" type="submit" value="Login" />
        </form>
        { errorState }
        <div className="dashboard__message">
          <span>Don't have an account?</span>
          <button className="toggle-login" onClick={this.toggleLogin}>Get Started</button>
        </div>
        <div className="dashboard__footer">
          <p>What's <a href="https://www.boldstack.io" target="_blank" rel="noopener noreferrer"><b>bold</b>stack?</a></p>
        </div>
      </div>
    )

    const signup = (
      <div className="dashboard__login">
        <Logo />
        <h2>Create Account</h2>
        <form onSubmit={ this.signup } className="form__login">
          <label htmlFor="firstname">
            First Name*
          </label>
          <input name="firstname" type="text" onChange={ this.handleEventChange } />
          <label htmlFor="lastname">
            Last Name*
          </label>
          <input name="lastname" type="text" onChange={ this.handleEventChange } />
          <label htmlFor="username">
            Username/Email*
          </label>
          <input name="username" type="text" onChange={ this.handleEventChange } />
          <div className="helper-text">{this.state.username_helper}</div>

          <label htmlFor="password">Password*</label>
          <input name="password" type="password" onChange={this.handleEventChange}/>
          <div className="helper-text">{this.state.password_helper}</div>
          <label htmlFor="password">Re-enter Password*</label>
          <input name="password_validate" type="password" onChange={this.handleEventChange}/>
          <div className="helper-text">{this.state.password_helper2}</div>
          <input className="cta-btn cta-btn--medium" type="submit" value="Create Account" />
          <div className="helper-text">{this.state.required_text}</div>
        </form>
        { errorState }
        <div className="dashboard__message">
          <span>Existing account?</span>
          <button className="toggle-login" onClick={this.toggleLogin}>Sign in</button>
        </div>
        <div className="dashboard__footer">
          <p>What's <a href="https://www.boldstack.io" target="_blank" rel="noopener noreferrer"><b>bold</b>stack?</a></p>
        </div>
      </div>
    )

    const resetPassword = (
      <div className="dashboard__login">
        <Logo />
        <h2>Reset Password</h2>
        <p>To reset your password, go to <a href="https://www.boldstack.io/support" target="_blank" rel="noopner noreferrer">https://www.boldstack.io/support</a> and submit a support request.</p>
        <br />
        <br />
        <Link to="/" className="cta-btn cta-btn--medium">
          Back to login
        </Link>
        { errorState }
      </div>
    )

    const passwordReset = (
      <div className="dashboard__login">
        <Logo />
        <h2>Reset Password</h2>
        <form onSubmit={ this.changePassword } className="form__login">
          <label htmlFor="password">Password*</label>
          <input name="password_reset" type="password" onChange={this.handleEventChange} autoComplete="current-password" />
          <div className="helper-text">{this.state.password_helper}</div>
          <label htmlFor="password">Re-enter Password*</label>
          <input name="password_reset_validate" type="password" onChange={this.handleEventChange}/>
          <div className="helper-text">{this.state.password_helper2}</div>
          <input className="cta-btn cta-btn--medium" type="submit" value="Reset Password" />
        </form>
        <div className="response">{this.state.response}</div>
      </div>
    )


    return (
      <Router>
        <Switch>
          <Route exact path='/'>
            {this.state.loggedIn ? <Redirect to='/dashboard' /> : ''}
            {this.state.loginDisplay ? login : ''}
            {this.state.signupDisplay ? signup : ''}
            {/* <Link to="/tables">Tables</Link> */}
          </Route>
          <Route exact path='/dashboard'>
            {!this.state.loggedIn ? <Redirect to='/' /> : ''}
            <Header userName={ this.state.userData !== '' ? `${this.state.userData.firstname} ${this.state.userData.lastname}` : 'Login' } logout={this.logout} hub_id={this.state.hub_id} domain={this.state.domain} portal={this.switchPortal} />
            <TableDashboard userData={this.state.userData} />
          </Route>
          <Route path='/tables/new'>
            <Header userName={ this.state.userData !== '' ? `${this.state.userData.firstname} ${this.state.userData.lastname}` : 'Login' } logout={this.logout} hub_id={this.state.hub_id} domain={this.state.domain} portal={this.switchPortal} />
            <NewTable id={this.state.userData.id} unpublish={this.hidePublish} />
          </Route>
          <Route path='/tables/:id' >
            <Header userName={ this.state.userData !== '' ? `${this.state.userData.firstname} ${this.state.userData.lastname}` : 'Login' } logout={this.logout} hub_id={this.state.hub_id} domain={this.state.domain} portal={this.switchPortal} />
            <Toolbar activatePublish={this.state.publish} table={this.state.activeTable} rows={this.state.activeTableRows} update={this.receiveRowUpdate} unpublish={this.hidePublish} completed />
            <AddResource userData={this.state.userData} publish={this.publish} updatedRows={this.state.activeTableRows} />
          </Route>
          <Route exact path='/reset-password'>
            {resetPassword}
          </Route>
          <Route exact path='/reset'>
            {passwordReset}
          </Route>
          <Route exact path='/import/'>
            <Header userName={ this.state.userData !== '' ? `${this.state.userData.firstname} ${this.state.userData.lastname}` : 'Login' } logout={this.logout} hub_id={this.state.hub_id} domain={this.state.domain} portal={this.switchPortal} />
            <ImportTable userData={this.state.userData} />
          </Route>
          <Route exact path='/import/:id'>
            <Header userName={ this.state.userData !== '' ? `${this.state.userData.firstname} ${this.state.userData.lastname}` : 'Login' } logout={this.logout} hub_id={this.state.hub_id} domain={this.state.domain} portal={this.switchPortal} />
            <Toolbar activatePublish={this.state.publish} table={this.state.activeTable} rows={this.state.activeTableRows} update={this.receiveRowUpdate} unpublish={this.hidePublish} completed />
            <ExistingTable userData={this.state.userData} publish={this.publish} updatedRows={this.state.activeTableRows} />
          </Route>
        </Switch>
      </Router>
    )
  }
}

export default Dashboard;
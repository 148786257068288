import React from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Filters from './filters';

import Reorder, {
  reorder,
  reorderImmutable,
  reorderFromTo,
  reorderFromToImmutable
} from 'react-reorder';
import TableCard from './tableCard';

class CreateTable extends React.Component {

  state = {
    userData: '',
    table_name: '',
    resource_header: '',
    resource_description: '',
    resource_link: '',
    selectedFile: null,
    showCardLayout: false,
    tableID: '',
    activeTableRows: '',
    updatedRows: false,
    tables: [],
    image_type: '',
    cardStyle: '',
    currentRowImage: '',
    errorState: false,
    showEditButton: false,
    selectedRow: '',
    hasReordered: false,
    reorderComplete: false,
    showFilterModal: false,
    filterGroup: [],
    tableOptions: [],
  }

  onReorder (event, previousIndex, nextIndex, fromId, toId) {
    this.setState({
      activeTableRows: reorder(this.state.activeTableRows, previousIndex, nextIndex),
      hasReordered: true,
      reorderComplete: false,
    });
  }

  async componentDidMount() {
    this.setState({ 
      userData: this.props.userData,
      tables: this.props.userData.tables,
    });
  }

  handleEventChange = (event) => {
    const { target } = event;
    const { value } = target;
    const { name } = target;
    this.setState({
      [name]: value,
    });
  }

  handleKeyDown = (param) => async (e) => {
    if (e.key === 'Enter') {
      if (param === 'tablename') {
        const postBody = {
          id: this.state.userData.id,
          name: this.state.table_name,
          cardStyle: this.state.image_type,
          columns: this.state.filterGroup,
        }
        this.setState({ cardStyle: this.state.image_type });
        try {
          const table = await axios.post('/newTable', postBody);
          const tables = this.state.tables;
          const newTable = {
            id: table.data.table.id,
            name: table.data.table.name,
            card_style: table.data.table.card_style,
            options: table.data.table.options,
          }
          tables.push(newTable);
          this.setState({ tableID: table.data.table.id, showCardLayout: true, tables });
        } catch (err) {
          console.log(err);
        }
      }
    }
  }

  reset = () => {

    if (this.state.filterGroup.length > 0) {
      for (let i = 0; i < this.state.filterGroup.length; i += 1) {
        this.setState({ [this.state.filterGroup[i].columnName]: '' })
      }
    }

    this.setState({
      selectedRow: '',
      resource_header: '',
      resource_description: '',
      resource_link: '',
      currentRowImage: '',
      selectedFile: null,
      showEditButton: false,
    });
  }

  toggleTableEdit = async (tableID, card_style) => {
    // console.log(tableID, card_style);
    const postBody = {
      id: this.state.userData.id,
      tableId: tableID,
    }

    const findTable = this.state.tables.find(item => item.id === tableID);
    // console.log(findTable);
    if (findTable.options) {
      this.setState({ filterGroup: findTable.options })
    }
    this.setState({ cardStyle: card_style });

    const rows = await axios.post('/getTableRows', postBody);
    this.setState({ tableID, showCardLayout: true, activeTableRows: rows.data.rows.results });
  }

  createResourceCard = async () => {
    try {
      const formData = new FormData();

      // Update the formData object 

      if (this.state.filterGroup.length > 0) {
        const column = [];
        for (let i = 0; i < this.state.filterGroup.length; i += 1) {
          const name = this.state.filterGroup[i].columnName;
          const customColumnObject = { value: this.state[name], name };
          column.push(customColumnObject);
        }
        formData.append('customColumn', JSON.stringify(column));
      }

      formData.append('image', this.state.selectedFile);
      formData.append('name', this.state.resource_header);
      formData.append('description', this.state.resource_description);
      formData.append('link', this.state.resource_link);
      formData.append('table_id', this.state.tableID);
      formData.append('id', this.state.userData.id);
  
  
      const send = await axios.post('/createResource', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      });
  
      const postBody = {
        id: this.state.userData.id,
        tableId: this.state.tableID,
      }
  
      const rows = await axios.post('/getTableRows', postBody);
      this.setState({ activeTableRows: rows.data.rows.results });
      this.reset();
    } catch (err) {
      this.setState({ errorState: true });
    }
  }

  onFileChange = event => { 
    this.setState({ selectedFile: event.target.files[0] });
  }; 

  removeTable = async (tableID) => {
    // console.log(tableID);
    const postBody = {
      id: this.state.userData.id,
      tableId: tableID,
    };

    const deleted = await axios.post('/deleteTable', postBody);
    // console.log(deleted.status);
    const newTables = this.state.userData.tables.filter(item => item.id !== tableID);

    this.setState({ tables: newTables });
    this.props.tableRefresh(true);
  }

  removeRow = async (rowID) => {
    try {
      const postBody = {
        id: this.state.userData.id,
        tableID: this.state.tableID,
        rowID,
      }
      const removeRow = await axios.post('/deleteRow', postBody);
      const currentRows = this.state.activeTableRows;
      const updatedRows = currentRows.filter(item => item.id !== rowID);
      this.setState({ activeTableRows: updatedRows })
      this.reset();
      // console.log(removeRow.status);
    } catch (err) {
      this.setState({ errorState: true })
    }
  }

  editRow = async (rowID) => {
    const selectEditableRow = this.state.activeTableRows.filter(item => item.id === rowID);
    let image;

    if (selectEditableRow[0].values.Image) {
      image = selectEditableRow[0].values.Image.url;
    } else {
      image = 'No image selected';
    }

    if (this.state.filterGroup.length > 0) {
      for (let i = 0; i < this.state.filterGroup.length; i += 1) {
        if (selectEditableRow[0].values[this.state.filterGroup[i].columnName]) {
          this.setState({ [this.state.filterGroup[i].columnName]: selectEditableRow[0].values[this.state.filterGroup[i].columnName].id })
        }
      }
    }

    this.setState({
      selectedRow: rowID,
      resource_header: selectEditableRow[0].values.Title,
      resource_description: selectEditableRow[0].values.Description,
      resource_link: selectEditableRow[0].values.Link,
      currentRowImage: image,
      selectedFile: null,
      showEditButton: true
    })
  }

  editResourceCard = async () => {
    try {
      const formData = new FormData(); 

      // Update the formData object 
      if (this.state.selectedFile) {
        formData.append('image', this.state.selectedFile);
      }

      if (this.state.filterGroup.length > 0) {
        const column = [];
        for (let i = 0; i < this.state.filterGroup.length; i += 1) {
          const name = this.state.filterGroup[i].columnName;
          const customColumnObject = { value: this.state[name], name };
          column.push(customColumnObject);
        }
        formData.append('customColumn', JSON.stringify(column));
      }

      formData.append('name', this.state.resource_header);
      formData.append('description', this.state.resource_description);
      formData.append('link', this.state.resource_link);
      formData.append('tableID', this.state.tableID);
      formData.append('rowID', this.state.selectedRow)
      formData.append('id', this.state.userData.id);
  
  
      const update = await axios.post('/editRow', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      });
      // console.log(update.status);
  
      const postBody = {
        id: this.state.userData.id,
        tableId: this.state.tableID,
      }
  
      const rows = await axios.post('/getTableRows', postBody);
      this.setState({ activeTableRows: rows.data.rows.results });
      this.reset();
    } catch (err) {
      this.setState({ errorState: true });
    }
  }

  submitRowOrder = async () => {
    try {
      const postBody = {
        id: this.state.userData.id,
        tableID: this.state.tableID,
        tableRows: this.state.activeTableRows
      }
  
      const submit = await axios.post('/updateRowOrder', postBody);
      // console.log(submit.status);
  
      const getRowInfo = {
        id: this.state.userData.id,
        tableId: this.state.tableID,
      }
  
      const rows = await axios.post('/getTableRows', getRowInfo);
      this.setState({ activeTableRows: rows.data.rows.results, reorderComplete: true });
    } catch (err) {
      console.log(err);
      this.setState({ errorState: true });
    }
  }

  displayFilterModal = () => {
    if (!this.state.showFilterModal && this.state.filterGroup.length < 2) {
      this.setState({ showFilterModal: true });
    } else {
      this.setState({ showFilterModal: false });
    }
  }

  toggleFilterGroup = (items) => {
    const filterGroupArray = this.state.filterGroup;
    filterGroupArray.push(items);
    this.setState({ filterGroup: filterGroupArray, showFilterModal: false });
  }

  render () {
    let rowData;
    let buttonToggle;
    let draggableGrid;
    let filterGroups;
    

    if (this.state.showEditButton) {
      buttonToggle = <div className="edit-button-holder">
        <p>Current Image: {this.state.currentRowImage}</p>
        <button onClick={this.editResourceCard} className="addRow">Submit Changes</button>
      </div>
    } else {
      buttonToggle = <button onClick={this.createResourceCard} className="addRow">Add Resouce Item</button>
    }

    if (this.state.filterGroup.length > 0) {
      filterGroups = (
        this.state.filterGroup.map((item, i) => (
          <div className="filter" key={item.columnName}>
            <div className="filter-name">{item.columnName}</div>
            <select name={item.columnName} value={this.state[item.columnName]} onChange={this.handleEventChange}>
              <option>--Choose--</option>
              {item.options.map((element, i) => {
              return <option key={i} value={i + 1}>{element.name}</option>
              })}
            </select>
          </div>
        ))
      )
    }

    const cardLayout = (
      <div className="input-group cards">
        <div className="table-name">Hubdb: {this.state.tableID}</div>
        <div className="display-area">
          <h3>Resource Card</h3>
          <input name="resource_header" type="text" placeholder="Your Header" value={this.state.resource_header} onChange={this.handleEventChange} />
          <textarea name="resource_description" placeholder="Resource Description" value={this.state.resource_description} onChange={this.handleEventChange} rows="4"/>
          <input name="resource_link" type="text" placeholder="Resource url" value={this.state.resource_link} onChange={this.handleEventChange} />
          <input type="file" onChange={this.onFileChange} ref={this.fileRef} />
          {filterGroups}
          {buttonToggle}
        </div>
      </div>
    )

    const tableOptions = (
      <div className="table-choice-contain">
        <h2>Or Select a Previous Table to Edit</h2>
        {this.state.tables.map((item, i) => (
          <TableCard key={item.id} tableName={item.name} tableId={item.id} clickFunction={() => this.toggleTableEdit(item.id, item.card_style)} />
          // <div className="table-option" key={item.name}>
          //   <div className="table-option-name" onClick={() => this.toggleTableEdit(item.id, item.card_style)}>{item.name}</div>
          //   <div className="table-option-id" onClick={() => this.toggleTableEdit(item.id, item.card_style)}>{item.id}</div>
          //   <div className="remove-table" onClick={() => this.removeTable(item.id)}><FontAwesomeIcon icon={faTimes} /></div>
          // </div>
        ))}
      </div>
    )

    const error = (
      <div className="error">
        <h2>An Error Occured. Please Try again</h2>
      </div>
    )

    if (this.state.activeTableRows && this.state.cardStyle === 'Card Image') {
      draggableGrid = (
        <Reorder
          reorderId="my-list" // Unique ID that is used internally to track this list (required)
          reorderGroup="reorder-group" // A group ID that allows items to be dragged between lists of the same group (optional)
          // getRef={this.wrapper} // Function that is passed a reference to the root node when mounted (optional)
          component="div" // Tag name or Component to be used for the wrapping element (optional), defaults to 'div'
          placeholderClassName="placeholder" // Class name to be applied to placeholder elements (optional), defaults to 'placeholder'
          draggedClassName="dragged" // Class name to be applied to dragged elements (optional), defaults to 'dragged'
          // lock="horizontal" // Lock the dragging direction (optional): vertical, horizontal (do not use with groups)
          holdTime={0} // Default hold time before dragging begins (mouse & touch) (optional), defaults to 0
          touchHoldTime={0} // Hold time before dragging begins on touch devices (optional), defaults to holdTime
          mouseHoldTime={0} // Hold time before dragging begins with mouse (optional), defaults to holdTime
          onReorder={this.onReorder.bind(this)} // Callback when an item is dropped (you will need this to update your state)
          autoScroll={true} // Enable auto-scrolling when the pointer is close to the edge of the Reorder component (optional), defaults to true
          disabled={false} // Disable reordering (optional), defaults to false
          disableContextMenus={true} // Disable context menus when holding on touch devices (optional), defaults to true
          >
          { 
            this.state.activeTableRows.map((item, i) => {
              if (item.values.Image) {
                return <div className="card" key={'data_' + item + i}><FontAwesomeIcon icon={faTimes} onClick={() => this.removeRow(item.id)} />
                  <div className="row-data row-card" onClick={() => this.editRow(item.id)}>
                    <div className="card-inner">
                      <div><h2>{item.values.Title}</h2></div>
                      <div><p>{item.values.Description}</p></div>
                      <div className="card-image-holder"><img src={item.values.Image.url} alt={'card_logo_' + i}></img></div>
                    </div>
                  </div>
                </div>
              } else {
                return <div className="card" key={'data_' + item + i}><FontAwesomeIcon icon={faTimes} onClick={() => this.removeRow(item.id)} />
                    <div className="row-data row-card" onClick={() => this.editRow(item.id)}>
                      <div className="card-inner">
                        <div><h2>{item.values.Title}</h2></div>
                        <div><p>{item.values.Description}</p></div>
                      </div>
                  </div>
                </div>
              }
            })
          }
        </Reorder>
      )
    } else if (this.state.activeTableRows && this.state.cardStyle === 'Card Background') {
      draggableGrid = (
        <Reorder
          reorderId="my-list" // Unique ID that is used internally to track this list (required)
          reorderGroup="reorder-group" // A group ID that allows items to be dragged between lists of the same group (optional)
          // getRef={this.wrapper} // Function that is passed a reference to the root node when mounted (optional)
          component="div" // Tag name or Component to be used for the wrapping element (optional), defaults to 'div'
          placeholderClassName="placeholder" // Class name to be applied to placeholder elements (optional), defaults to 'placeholder'
          draggedClassName="dragged" // Class name to be applied to dragged elements (optional), defaults to 'dragged'
          // lock="horizontal" // Lock the dragging direction (optional): vertical, horizontal (do not use with groups)
          holdTime={0} // Default hold time before dragging begins (mouse & touch) (optional), defaults to 0
          touchHoldTime={0} // Hold time before dragging begins on touch devices (optional), defaults to holdTime
          mouseHoldTime={0} // Hold time before dragging begins with mouse (optional), defaults to holdTime
          onReorder={this.onReorder.bind(this)} // Callback when an item is dropped (you will need this to update your state)
          autoScroll={true} // Enable auto-scrolling when the pointer is close to the edge of the Reorder component (optional), defaults to true
          disabled={false} // Disable reordering (optional), defaults to false
          disableContextMenus={true} // Disable context menus when holding on touch devices (optional), defaults to true
          >
          { 
            this.state.activeTableRows.map((item, i) => {
              if (item.values.Image) {
                return <div className="card" key={'data_' + item + i}><FontAwesomeIcon icon={faTimes} onClick={() => this.removeRow(item.id)} />
                    <div className="row-data row-card" style={{backgroundImage: 'url(' + item.values.Image.url ||  + ')', backgroundSize: 'cover'}} onClick={() => this.editRow(item.id)}>
                    <div className="card-inner">
                      <div><h2>{item.values.Title}</h2></div>
                      <div><p>{item.values.Description}</p></div>
                    </div>
                  </div>
                </div>
              } else {
                return <div className="card" key={'data_' + item + i}><FontAwesomeIcon icon={faTimes} onClick={() => this.removeRow(item.id)} />
                  <div className="row-data row-card" onClick={() => this.editRow(item.id)}>
                  <div className="card-inner">
                    <div><h2>{item.values.Title}</h2></div>
                    <div><p>{item.values.Description}</p></div>
                  </div>
                </div></div>
              }
            })
          }
        </Reorder>
      )
    }

    const updateRowOrder = (
      <button className="update-reorder" onClick={this.submitRowOrder}>Submit Row Re-ordering changes</button>
    )

    const filters = (
      <Filters filterGroup={this.toggleFilterGroup} />
    )

    const displayFilterGroup = (
      this.state.filterGroup.map((item, i) => (
        <div className="display-filter-group" key={i}>
          <p>Filter group created: {item.columnName}</p>
        </div>
      ))
    )


    return (
      <div className="create-table">
        <h2>Create Your Resource Table</h2>
        {(!this.state.showCardLayout) ? 
          <div className="create-resource-card">
            <h4>Card Style</h4>
            <div className="radio-group">
              <div className="radio-group-item">
                <label>
                  <input type="radio" value="Card Background" name="image_type" onChange={this.handleEventChange} />
                  Card w/Full Background Image
                </label>
              </div>
              <div className="radio-group-item">
                <label>
                  <input type="radio" value="Card Image" name="image_type" onChange={this.handleEventChange} />
                  Standard
                </label>
              </div>
            </div>
            <div className="add-filters">
              <button onClick={this.displayFilterModal}>Add Filter Group</button>
            </div>
            {(this.state.showFilterModal) ? filters: ''}
            {(this.state.filterGroup.length > 0) ? displayFilterGroup: ''}
            <input name="table_name" type="text" placeholder="Enter table name" onChange={this.handleEventChange} value={this.state.table_name} onKeyDown={this.handleKeyDown('tablename')}/>
          </div> : ''}
        <div className="create-table-inputs">
          {(this.state.showCardLayout) ? cardLayout : ''}
        </div>
        {(this.state.tables && !this.state.showCardLayout) ? tableOptions : ''}
        {(this.state.activeTableRows) ? <p>Select Card to Edit OR Drag to Re-reorder rows</p> : ''}
        {(this.state.hasReordered) ? updateRowOrder : ''}
        {(this.state.reorderComplete) ? <p>Row re-order complete</p> : ''}
        <div className="click-and-drag">
          {draggableGrid}
        </div>
        {(this.state.errorState) ? error : ''}
      </div>
    )
  }
}

export default CreateTable;
import React from 'react';
import axios from 'axios';


class ImportTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTables: [],
      activeTableColumns: [],
      displayModal: false,
      setupText: 'Select your Title column',
      step: 0,
    }
  }

  async componentDidMount() {
    const postBody = {
      user: localStorage.getItem('user'),
    }
    const allTables = await axios.post('/getAll', postBody);
    this.findTableMatches(allTables.data.tableData);
  }

  
  findTableMatches = (tables) => {
    
    const appTables = this.props.userData.tables;
    const activeTables = [];
    for (let i = 0; i < appTables.length; i += 1) {
      const id = appTables[i].id;
      const findMatch = tables.find(item => item.id === id);
      if (findMatch !== undefined) {
        activeTables.push(findMatch);
      }
    }

    const findNamingScheme = tables.filter(item => item.name.indexOf('boldstack_db__') === 0);
    for (let i = 0; i < findNamingScheme.length; i += 1) {
      const id = findNamingScheme[i].id
      const match = activeTables.find(item => item.id === id);
      if (match === undefined) {
        activeTables.push(findNamingScheme[i]);
      }
    }

    const difference = tables.filter(x => !activeTables.includes(x));

    this.setState({ activeTables: difference });
  }

  displaySetup = (id) => {
    const findTableColumns = this.state.activeTables.find(item => item.id === id);
    this.setState({ activeTableColumns: findTableColumns.columns, displayModal: true });
  }

  cancel = () => {
    for (let i = 0; i < this.state.activeTableColumns.length; i += 1) {
      const id = this.state.activeTableColumns[i].id;
      if (this.state[id]) {
        this.setState({ [id]: undefined });
      }
    }

    this.setState({ activeTableColumns: [],
      displayModal: false,
      title: '',
      description: '',
      image: '',
      setupText: 'Select your Title',
      step: 0,
     });
  }

  selection = (label, id) => {
    if (this.state.step === 0) {
      this.setState({ title: label, step: 1, setupText: 'Select your Description', [id]: true });
    } else if (this.state.step === 1) {
      this.setState({ description: label, step: 2, setupText: 'Select your Image', [id]: true });
    } else if (this.state.step === 2) {
      this.setState({ image: label, step: 3, setupText: 'Create your table!', [id]: true });
    }
  }
   
  render() {
    const tables = (
      <div className="existing-tables__grid">
        {this.state.activeTables.map((item, i) => (
          <div className="table-card" key={item.id} onClick={() => this.displaySetup(item.id)}>
            <div className="table-card__inner">
              <div className="table-card__main">
                <span className="table-card__name">{item.label}</span>
                <span className="table-card__id">{item.id}</span>
              </div>
              <div className="table-card__actions">
                <div className="table-card__count">
                  {item.rowCount}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    )

    const modal = (
      <div className="existing-tables__viewport">
        <div className="existing-tables__modal">
          <div className="existing-tables__modal-inner">
            <div className="existing-tables__interact">
              <div className="title">
                <h1>Map your columns</h1>
              </div>
              <div className="buttons">
                <button className="cta-btn--stroke cta-btn--medium" onClick={this.cancel}>Cancel</button>
                {/* <button className="cta-btn cta-btn--medium">Next</button> */}
              </div>
            </div>
            <div className="existing-tables__modal-main">
              <div className="existing-tables__modal-column">
                <h2>{this.state.setupText}</h2>
                <div className="existing-tables__modal-column-items">
                  {this.state.activeTableColumns.map((item, i) => {
                    if (this.state[item.id]) {
                      return (
                        <button className="column-name cta-btn--stroke cta-btn--small cta-btn--inactive" key={item.id} onClick={() => this.selection(item.label, item.id)}>
                          <div>{item.label} </div>
                          <div>Type: {item.type}</div>
                        </button>
                      )
                    } return (
                      <button className="column-name cta-btn--stroke cta-btn--small" key={item.id} onClick={() => this.selection(item.label, item.id)}>
                        <div>{item.label} </div>
                        <div>Type: {item.type}</div>
                      </button>
                    )
                  })}
                </div>
                {(this.state.step > 1) ? <button className="cta-btn">Skip</button> : ''}
              </div>
              <div className="existing-tables__modal-column">
                <div className="existing-tables__selections">
                  <div className="existing-tables__selections-title">Title: {this.state.title || ''}</div>
                  <div className="existing-tables__selections-description">Description: {this.state.description || ''}</div>
                  <div className="existing-tables__selections-image">Image: {this.state.image || ''}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )

    const backdrop = (
      <div className="backdrop" />
    )
    return (
      <div className="existing-tables">
        <div className="new-table__grid">
          <div className="new-table__column"></div>
          <div className="new-table__column">
            <div className="new-table__column-inner">
              <h2>Select an Existing Table</h2>
              {tables}
            </div>
          </div>
          <div className="new-table__column new-table__column--help">
            <div className="helper-text">
              <p className="intro-text"><i className="far fa-lightbulb" />Pro Tip</p>
              <p>To get best results with our resources module, please select a column that can be used for a card title, description, and image.</p>
              <p>If this isn't applicable to your table currently, these columns will be added to your table</p>
            </div>
          </div>
        </div>
        {(this.state.displayModal ? modal : '')}
        {(this.state.displayModal ? backdrop : '')}
      </div>
    );
  }
}

export default ImportTable;

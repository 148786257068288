import React from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';

class Toolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTableRows: '',
      showModal: false,
      activeTable: '',
      hideDelete: false,
    };
  }

  goBack = async () => {
    await this.props.unpublish();
  }

  publish = async () => {
    try {
      const postBody = {
        id: localStorage.getItem('user'),
        tableID: this.props.table,
        tableRows: this.props.rows
      }
  
      const submit = await axios.post('/updateRowOrder', postBody);
      // console.log(submit.status);
  
      const getRowInfo = {
        id: localStorage.getItem('user'),
        tableId: this.props.table,
      }
  
      const rows = await axios.post('/getTableRows', getRowInfo);
      await this.setState({ activeTableRows: rows.data.rows.results });
      await this.props.unpublish();
      await this.props.update(rows.data.rows.results);
      localStorage.removeItem(this.state.activeTable);
      window.location.reload();
    } catch (err) {
      console.log(err);
      this.setState({ errorState: true });
    }
  }

  removeTable = async () => {
    const postBody = {
      id: localStorage.getItem('user'),
      tableId: this.state.activeTable,
    };

    try {
      const deleted = await axios.post('/deleteTable', postBody);
      // console.log(deleted.status);
      window.location = '/dashboard';
    } catch (err) {
      console.log(err);
    }
  }

  removeTableModal = () => {
    this.setState({ showModal: true });
  }

  hideModal = () => {
    this.setState({ showModal: false });
  }

  componentDidMount() {
    let url = window.location.pathname;
    url = url.split('/tables/');
    if (url.indexOf('new') > -1) {
      this.setState({ hideDelete: true });
    } else {
      this.setState({ activeTable: url[1], hideDelete: false });
    }
  }


  render() {
    const publishButtonClass = this.props.activatePublish ? 'cta-btn cta-btn--small cta-btn--publish' : 'cta-btn cta-btn--small cta-btn--publish inactive';
    const completedClass = this.props.completed ? 'completed' : '';
    const deleteButton = <button className="cta-btn cta-btn--small cta-btn--delete" onClick={this.removeTableModal}>Delete</button>;

    const publishFunction = (event) => {
      event.target.classList.add('loading');
      this.publish();
    };

    const toolbarModal = (
      <div className="toolbar__modal">
        <div className="toolbar__modal-inner">
          <h4>Warning: This will delete the entire table from your account</h4>
          <p>Proceed?</p>
          <div className="toolbar__modal-buttons">
            <button className="cta-btn cta-btn--small" onClick={this.removeTable}>Yes</button>
            <button className="cta-btn cta-btn--small" onClick={this.hideModal}>No</button>
          </div>
        </div>
      </div>
    )

    return (
      <div className="toolbar">
        <div className="toolbar__inner">
          <div className="toolbar__left">
            <Link className="back" to="/" onClick={this.goBack}><i className="fas fa-arrow-left" /></Link>
            <p>{ this.props.tableName ? this.props.tableName : 'Your Resource Project' }</p>
          </div>
          <div className="toolbar__center">
            <div className="toolbar__steps completed">
              <div className="circle">1</div>
              <div className="name">Title + Filters</div>
            </div>
            <div className={`toolbar__steps ${completedClass}`}>
              <div className="circle">2</div>
              <div className="name">Add Resources</div>
            </div>
          </div>
          <div className="toolbar__right">
            {!this.state.hideDelete ? deleteButton : ''}
            {/* <button className="cta-btn cta-btn--small cta-btn--delete" onClick={this.removeTableModal}>Delete</button> */}
            <button className={publishButtonClass} onClick={ publishFunction }><i className="fas fa-spinner" /> Publish</button>
          </div>
        </div>
        {this.state.showModal ? toolbarModal : ''}
        {this.state.showModal ? <div className="backdrop" /> : ''}
      </div>
    );
  }
}

export default Toolbar;
import React from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';

class TableSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTables: [],
    }
  }

  async componentDidMount() {
    const postBody = {
      user: this.props.id,
    }
    const allTables = await axios.post('/getAll', postBody);
    this.findTableMatches(allTables.data.tableData);
  }

  findTableMatches = (tables) => {
    const appTables = this.props.tables;
    const activeTables = [];
    for (let i = 0; i < appTables.length; i += 1) {
      const id = appTables[i].id;
      const findMatch = tables.find(item => item.id === id);
      if (findMatch !== undefined) {
        activeTables.push(findMatch);
      }
    }

    const findNamingScheme = tables.filter(item => item.name.indexOf('boldstack_db__') > -1);
    for (let i = 0; i < findNamingScheme.length; i += 1) {
      const id = findNamingScheme[i].id
      const match = activeTables.find(item => item.id === id);
      if (match === undefined) {
        activeTables.push(findNamingScheme[i]);
      }
    }
    this.setState({ activeTables });
    this.props.activeTables(activeTables);
  }


  render() {
    let tables;

    if (this.state.activeTables.length > 0) {
      tables = this.state.activeTables.map((item, i) => (
        <Link to={`/tables/${item.id}`} key={i} className="table-sidebar__item">
          <h4 className="table-sidebar__item-title">{item.label}</h4>
          <div className="table-sidebar__item-count">{item.rowCount}</div>
        </Link>
      ))
    } else {
      tables = (
        <div><h4>No HubDB resource projects found</h4></div>
      )
    }

    return (
      <div className="table-sidebar">
        <div className="table-sidebar__inner">
          <div className="table-sidebar__header">
            <h4>HubDB Resource Projects</h4>
            <Link to="/tables/new" className="table-sidebar__new-table">
              <i className="fas fa-plus"></i>
            </Link>
          </div>
          {tables}
          <div className="table-sidebar__inner-spacer" />
        </div>
        <div className="table-sidebar__bottom">
          <a className="table-sidebar__help-link" href="https://www.boldstack.io/support" target="_blank" rel="noopener noreferrer"><i className="far fa-question-circle" />Need Help?</a>
        </div>
      </div>
    )
  }
}

export default TableSidebar;
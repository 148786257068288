import React from 'react';

class Tooltip extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <span className={ `tooltip tooltip--${ this.props.direction ? this.props.direction : 'right' }` }>
        <i class="fas fa-info-circle"></i>
        <span className="tooltip__tip">
          <div className="tooltip__tip-background"></div>
          { this.props.tip }
        </span>
      </span>
    );
  }
}

export default Tooltip;

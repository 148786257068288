import React from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPen, faTimes } from '@fortawesome/free-solid-svg-icons';
import Reorder, {
  reorder,
  reorderImmutable,
  reorderFromTo,
  reorderFromToImmutable
} from 'react-reorder';
import ResourceCard from './resourceCard';
import ResourceModal from './resourceModal';
import Toolbar from './toolbar';
import Tooltip from './tooltip';

class AddResource extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTableRows: [],
      activeTableFilters: [],
      activeTableName: '',
      activeTableId: '',
      showModal: false,
      selectedFile: null,
      resource_description: '',
      resource_title: '',
      resource_link: '',
      resource_alt_text: '',
      activateButton: false,
    }

    this.onReorder = this.onReorder.bind(this);
  }

  async componentDidMount() {
    let url = window.location.pathname;
    url = url.split('/tables/');
    try {
      const postBody = {
        id: localStorage.getItem('user'),
        tableId: url[1],
      }

      const rows = await axios.post('/getTableRows', postBody);
      const tableDetails = await axios.post('/tableDetails', postBody);

      await this.setState({ activeTableRows: rows.data.rows.results });
      await this.findActiveTable(tableDetails.data.table);
    } catch (err) {
      this.setState({ error: 'Sorry, there was an error' });
    }
    this.checkPublishState(url[1]);
  }

  checkPublishState = (tableID) => {
    const table = localStorage.getItem(tableID);
    if (table) {
      this.props.publish(tableID, this.state.activeTableRows);
    }
  }

  onReorder (event, previousIndex, nextIndex, fromId, toId) {
    this.setState({
      activeTableRows: reorder(this.state.activeTableRows, previousIndex, nextIndex),
      hasReordered: true,
      reorderComplete: false,
    });
    this.props.publish(this.state.activeTableId, this.state.activeTableRows);
  }

  validateField = () => {
    if (this.state.resource_title !== '' && this.state.resource_description !== '' && this.state.resource_link  !== '' && this.state.resource_alt_text !== '') {
      this.setState({ activateButton: true });
    } else {
      this.setState({ activateButton: false });
    }
  }

  onFileChange = async event => { 
    await this.setState({ selectedFile: event.target.files[0] });
    this.validateField();
  };

  handleEventChange = async (event) => {
    // this.validateField();
    const { target } = event;
    const { value } = target;
    const { name } = target;
    await this.setState({
      [name]: value,
    });
    this.validateField();
  }

  findActiveTable = async (table) => {
    const activeTableFilters = [];
    const filters = table.columns.filter(item => item.type === 'SELECT');

    for (let i = 0; i < filters.length; i += 1) {
      const name = filters[i].name;
      const options = filters[i].options;
      const filterObject = {
        columnName: name,
        options,
      }
      activeTableFilters.push(filterObject);
    }

    await this.setState({
      activeTableFilters,
      activeTableId: table.id,
      activeTableName: table.name,
    });
  }

  reset = async () => {
    if (this.state.activeTableFilters.length > 0) {
      for (let i = 0; i < this.state.activeTableFilters.length; i += 1) {
        this.setState({ [this.state.activeTableFilters[i].columnName]: '' })
      }
    }

    await this.setState({
      selectedRow: '',
      resource_title: '',
      resource_description: '',
      resource_link: '',
      currentRowImage: '',
      selectedFile: null,
      showEditButton: false,
      resource_alt_text: '',
    });
    this.validateField();
  }

  createResourceCard = async () => {
    try {
      const formData = new FormData();

      // Update the formData object 

      if (this.state.activeTableFilters.length > 0) {
        const column = [];
        for (let i = 0; i < this.state.activeTableFilters.length; i += 1) {
          const name = this.state.activeTableFilters[i].columnName;
          const customColumnObject = { value: this.state[name], name };
          column.push(customColumnObject);
        }
        formData.append('customColumn', JSON.stringify(column));
      }

      formData.append('image', this.state.selectedFile);
      formData.append('name', this.state.resource_title);
      formData.append('description', this.state.resource_description);
      formData.append('link', this.state.resource_link);
      formData.append('resource_alt_text', this.state.resource_alt_text);
      formData.append('table_id', this.state.activeTableId);
      formData.append('id', localStorage.getItem('user'));


      const send = await axios.post('/createResource', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      });
  
      const postBody = {
        id: localStorage.getItem('user'),
        tableId: this.state.activeTableId,
      }
  
      const rows = await axios.post('/getTableRows', postBody);
      await this.setState({ activeTableRows: rows.data.rows.results });
      this.props.publish(this.state.activeTableId, this.state.activeTableRows);
      this.reset();
      this.fileRef.value = '';
    } catch (err) {
      this.setState({ errorState: true });
    }
  }

  editRow = async (rowID) => {
    const selectEditableRow = this.state.activeTableRows.filter(item => item.id === rowID);
    let image;

    if (selectEditableRow[0].values.Image) {
      image = selectEditableRow[0].values.Image.url;
    } else {
      image = 'No image selected';
    }

    if (this.state.activeTableFilters.length > 0) {
      for (let i = 0; i < this.state.activeTableFilters.length; i += 1) {
        if (selectEditableRow[0].values[this.state.activeTableFilters[i].columnName]) {
          this.setState({ [this.state.activeTableFilters[i].columnName]: selectEditableRow[0].values[this.state.activeTableFilters[i].columnName].id })
        }
      }
    }

    this.setState({
      selectedRow: rowID,
      selectedRowData: selectEditableRow[0].values,
      selectedFile: null,
      showModal: true,
    })
  }

  removeRow = async (rowID) => {
    try {
      const postBody = {
        id: this.props.userData.id,
        tableID: this.state.activeTableId,
        rowID,
      }
      const remove = await axios.post('/deleteRow', postBody);
      const currentRows = this.state.activeTableRows;
      const updatedRows = currentRows.filter(item => item.id !== rowID);
      await this.setState({ activeTableRows: updatedRows });
      this.props.publish(this.state.activeTableId, this.state.activeTableRows);
      this.reset();
      // console.log(remove.status);
    } catch (err) {
      this.setState({ errorState: true })
    }
  }

  submitRowOrder = async () => {
    try {
      const postBody = {
        id: localStorage.getItem('user'),
        tableID: this.state.activeTableId,
        tableRows: this.state.activeTableRows
      }
  
      const submit = await axios.post('/updateRowOrder', postBody);
      // console.log(submit.status);
  
      const getRowInfo = {
        id: localStorage.getItem('user'),
        tableId: this.state.activeTableId,
      }
  
      const rows = await axios.post('/getTableRows', getRowInfo);
      this.setState({ activeTableRows: rows.data.rows.results, reorderComplete: true });
    } catch (err) {
      console.log(err);
      this.setState({ errorState: true });
    }
  }

  closeModal = async (updated) => {
    if (updated) {
      const postBody = {
        id: localStorage.getItem('user'),
        tableId: this.state.activeTableId,
      }
  
      try {
        const rows = await axios.post('/getTableRows', postBody);
        this.setState({ activeTableRows: rows.data.rows.results, showModal: false });
        this.props.publish(this.state.activeTableId, this.state.activeTableRows);
        this.reset();
      } catch (err) {
        this.setState({ showModal: false });
        this.reset();
      }
    } else {
      this.setState({ showModal: false });
      this.reset();
    }
  }


  render() {
    let filterGroups;
    let draggableGrid;
    let filters;

    const addResourceButtonClass = this.state.activateButton ? 'cta-btn add' : 'cta-btn inactive add';

    if (this.props.filters) {
      filters = this.props.filters.map(filter => (<span className="resource-card__filter"><span>{ filter.label }</span><span>{ filter.value }</span></span>));
    }

    if (this.state.activeTableRows.length > 0) {
      draggableGrid = (
        <Reorder
          reorderId="my-list" // Unique ID that is used internally to track this list (required)
          reorderGroup="reorder-group" // A group ID that allows items to be dragged between lists of the same group (optional)
          // getRef={this.wrapper} // Function that is passed a reference to the root node when mounted (optional)
          component="div" // Tag name or Component to be used for the wrapping element (optional), defaults to 'div'
          placeholderClassName="placeholder" // Class name to be applied to placeholder elements (optional), defaults to 'placeholder'
          draggedClassName="dragged" // Class name to be applied to dragged elements (optional), defaults to 'dragged'
          // lock="horizontal" // Lock the dragging direction (optional): vertical, horizontal (do not use with groups)
          holdTime={0} // Default hold time before dragging begins (mouse & touch) (optional), defaults to 0
          touchHoldTime={0} // Hold time before dragging begins on touch devices (optional), defaults to holdTime
          mouseHoldTime={0} // Hold time before dragging begins with mouse (optional), defaults to holdTime
          onReorder={this.onReorder.bind(this)} // Callback when an item is dropped (you will need this to update your state)
          autoScroll={true} // Enable auto-scrolling when the pointer is close to the edge of the Reorder component (optional), defaults to true
          disabled={false} // Disable reordering (optional), defaults to false
          disableContextMenus={true} // Disable context menus when holding on touch devices (optional), defaults to true
        >
          { 
            this.state.activeTableRows.map((item, i) => {
              if (item.values.Image) {
                return (
                  // <ResourceCard
                  //   key={ 'data_' + item.id + i }
                  //   title={ item.values.Title }
                  //   description={ item.values.Description }
                  //   imgSrc={ item.values.Image.url }
                  //   link={ item.values.Link }
                  //   edit={ () => this.editRow(item.id) }
                  //   delete={ () => this.removeRow(item.id) }
                  // />
                  <div className="resource-card" key={item.id}>
                    <div className="resource-card__inner">
                      <div className="resource-card__main">
                        <span className="resource-card__title">{ item.values.Title }</span>
                        <span className="resource-card__description">{ item.values.Description.replace(/(&nbsp;|<([^>]+)>)/ig, "") }</span>
                        <img className="resource-card__image" src={ item.values.Image.url } alt={'card_logo_' + i} />
                        { filters }
                        <span className="resource-card__link">{ item.values.Link }</span>
                      </div>
                      <div className="resource-card__actions">
                        <div className="resource-card__edit" onClick={ () => this.editRow(item.id) }>
                          <FontAwesomeIcon icon={faPen} />
                        </div>
                      </div>
                    </div>
                    <button className="resource-card__delete" onClick={ () => this.removeRow(item.id) }>
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  </div>
                )
              } else {
                return (
                  <div className="resource-card" key={item.id}>
                    <div className="resource-card__inner">
                      <div className="resource-card__main">
                        <span className="resource-card__title">{ item.values.Title }</span>
                        <span className="resource-card__description">{ item.values.Description }</span>
                        {/* <img className="resource-card__image" src={ this.props.imgSrc } alt={ this.props.imgAlt } /> */}
                        { filters }
                        <span className="resource-card__link">{ item.values.Link }</span>
                      </div>
                      <div className="resource-card__actions">
                        <div className="resource-card__edit" onClick={ () => this.editRow(item.id) }>
                          <FontAwesomeIcon icon={faPen} />
                        </div>
                      </div>
                    </div>
                    <button className="resource-card__delete" onClick={ () => this.removeRow(item.id) }>
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  </div>
                )
              }
            })
          }
        </Reorder>
      )
    }


    if (this.state.activeTableFilters && this.state.activeTableFilters.length > 0) {
      filterGroups = (
        this.state.activeTableFilters.map((item, i) => (
          <div className="resources-sidebar__input-group" key={item.columnName}>
            <label className="resources-sidebar__input-group-label">{item.columnName}</label>
            <select name={item.columnName} value={this.state[item.columnName]} onChange={this.handleEventChange}>
              <option>Make a Selection</option>
              {item.options.map((element, i) => {
              return <option key={i} value={i + 1}>{element.name}</option>
              })}
            </select>
          </div>
        ))
      )
    }


    const addResourceInputs = (
      <div className="resources-sidebar__input">
        <div className="resources-sidebar__input-group">
          <label>Resource Name*</label>
          <input name="resource_title" type="text" value={this.state.resource_title} onChange={this.handleEventChange} />
        </div>
        <div className="resources-sidebar__input-group">
          <label>Resource Description*</label>
          <textarea name="resource_description" value={this.state.resource_description} onChange={this.handleEventChange} rows="4"/>
          <span className="resources-sidebar__input-info-text">150 Characters</span>
        </div>
        {filterGroups}
        <div className="resources-sidebar__input-group">
          <label>Resource Url*</label>
          <input name="resource_link" type="text" placeholder="https://" value={this.state.resource_link} onChange={this.handleEventChange} />
        </div>
        <div className="resources-sidebar__input-group">
          <label>Resource Image*</label>
          <input type="file" onChange={this.onFileChange} ref={ref => this.fileRef = ref} />
          <span className="resources-sidebar__input-info-text">Upload a 400x400 .jpg, .png, or .gif 10MB limit</span>
        </div>
        <div className="resources-sidebar__input-group">
          <label>Resource Image Alt Text*</label>
          <input name="resource_alt_text" type="text" placeholder="" value={this.state.resource_alt_text} onChange={this.handleEventChange} />
        </div>
        <button onClick={this.createResourceCard} className={addResourceButtonClass}><FontAwesomeIcon icon={faPlus} />Add Resource Item</button>
      </div>
    )

    return (
      <div className="resources">
        <div className="resources__viewport">
          <div className="resources-sidebar">
            <div className="resources-sidebar__inner">
              <div className="resources-sidebar__add-resources">
                <h2>Add Resources</h2>
                {addResourceInputs}
              </div>
              <div className="resources-sidebar__inner-spacer" />
            </div>
          </div>
          <div className="resources__grid">
            {draggableGrid}
          </div>
          {this.state.showModal ? <ResourceModal data={this.state.selectedRowData} onClose={this.closeModal} filters={this.state.activeTableFilters} rowId={this.state.selectedRow} tableId={this.state.activeTableId} /> : ''}
          {this.state.showModal ? <div className="backdrop" /> : ''}
        </div>
      </div>
    )
  }
}

export default AddResource;
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';

class TableCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  // handleClick = (id) => {
  //   this.props.clickFunction(id);
  // }

  render() {
    return (
      <div className="table-card">
        <Link className="table-card__inner" to={`tables/${this.props.tableId}`} >
          <div className="table-card__main">
            <span className="table-card__name">{this.props.tableName}</span>
            <span className="table-card__id">{this.props.tableId}</span>
          </div>
          <div className="table-card__actions">
            <div className="table-card__edit">
              <FontAwesomeIcon icon={faPen} />
            </div>
            <div className="table-card__count">
              {this.props.tableCount}
            </div>
          </div>
        </Link>
      </div>
    )
  }
}

export default TableCard;
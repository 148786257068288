import React from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

class Filters extends React.Component {

  state = {
    activeInput: '',
    filter_group_name: '',
    displayOptions: false,
    columnOption: false,
    totalOptions: 1,
    enable_filter: false,
    skip: false,
    numberOfFilterGroups: 1,
    filters: [],
  }

  componentDidUpdate() {
    if (this.state.activeInput && this.state.activeInput.nextElementSibling) this.state.activeInput.nextElementSibling.firstChild.focus();
  }

  handleEventChange = (event) => {
    const { target } = event;
    const { value } = target;
    const { name } = target;
    this.setState({
      [name]: value,
    });
  }

  displayFilter = async () => {
    if (this.state.enable_filter) {
      this.setState({ enable_filter: false });
      this.props.showFilters();
    } else {
      const filterArray = [];
      const filterObject = {
        columnName: '',
        numberOfOptions: 1,
        options: []
      }
      filterArray.push(filterObject)
      await this.setState({ enable_filter: true, filters: filterArray });
      this.props.showFilters();
    }
  }

  handleNameChange = (param) => (event) => {
    const filters = this.state.filters;
    filters[param].columnName = event.target.value;
    this.setState({ filters });
  }

  handleKeyDown = (param) => async (e) => {
    if (e.key === 'Enter' && param === 'filtername') {
      this.setState({ displayOptions: true });
    }
  }

  addColumnOption = (number, inputVal, e) => {
    if (!inputVal || inputVal.trim() === '') {
      return;
    } else {
      const filters = this.state.filters;
      let count = parseInt(filters[number].numberOfOptions);
      count += 1;
      filters[number].numberOfOptions = count;
      this.setState({ filters });
      this.setState({ activeInput: e.target.parentNode });
    }
  }

  removeColumnOption = (number, index) => {
    const filters = this.state.filters;
    let count = parseInt(filters[number].numberOfOptions);
    count -= 1;
    filters[number].numberOfOptions = count;
    this.setState({ filters,
      [`option-input__${number}-${index}`]: '',
    });
  }

  columnSubmit = async () => {
    const filters = this.state.filters;

    for (let i = 0; i < filters.length; i += 1) {
      const filterArray = [];
      for (let k = 0; k < filters[i].numberOfOptions; k += 1) {
        const filterObject = {
          name: this.state['option-input__' + i + '-' + k],
        }
        filterArray.push(filterObject);
      }
      filters[i].options = filterArray
    }
    this.setState({ filters });

    if (this.state.filters[0].options.length > 0) {
      // this.props.filters(filters);
      this.props.advance(filters);
    }
  }

  skipFilters = () => {
    this.props.advance();
    // this.setState({ skip: true, totalOptions: 0 });
  }

  addFilterGroup = () => {
    const filterArray = this.state.filters;
    let number = this.state.numberOfFilterGroups;
    number += 1;
    const filterObject = {
      columnName: '',
      numberOfOptions: 1,
      options: []
    }
    filterArray.push(filterObject);
    this.setState({ activeInput: '', numberOfFilterGroups: number, filters: filterArray })
  }

  removeFilterGroup = () => {
    const filters = this.state.filters;
    const filterArray = [];

    let number = this.state.numberOfFilterGroups;
    const newFilters = filters.splice(number - 1, 1);
    number -= 1;
    if (number > 0) {
      filterArray.push(newFilters);
      this.setState({ numberOfFilterGroups: number, filters: filterArray })
    }
  }

  render() {
    const filterOption = (number, item) => (
      [...Array(number)].map((element, index) => {
        if (index === 0) {
          return <div className="option-inputs" key={index}>
            <input
              name={'option-input__' + item + '-' + index}
              type="text"
              placeholder="Filter option"
              onFocus={() => this.setState({ activeInput: '' })}
              onKeyUp={(e) => e.key === 'Enter' ? this.addColumnOption(item, e.target.value, e) : null}
              onChange={this.handleEventChange} value={this.state['option-input__' + item + '-' + index] || ''}
            />
            <div onClick={(e) => this.addColumnOption(item, this.state['option-input__' + item + '-' + index], e)} className="add"><FontAwesomeIcon icon={faPlus} /></div>
          </div> 
          } else {
            return <div className="option-inputs" key={index}>
              <input
                name={'option-input__' + item + '-' + index}
                type="text" placeholder="Filter option"
                onFocus={() => this.setState({ activeInput: '' })}
                onKeyUp={(e) => e.key === 'Enter' ? this.addColumnOption(item, e.target.value, e) : null}
                onChange={this.handleEventChange} value={this.state['option-input__' + item + '-' + index] || ''}
              />
              <div onClick={() => this.removeColumnOption(item, index)} className="minus"><FontAwesomeIcon icon={faMinus} /></div>
              <div onClick={(e) => this.addColumnOption(item, this.state['option-input__' + item + '-' + index], e)} className="add"><FontAwesomeIcon icon={faPlus} /></div>
            </div> 
          }
        }
      )
    )

    const createFilterOptions = (
      <div className="filter__contain">
        {this.state.filters.map((element, index) => (
        <div className="filter__input-grid" key={index}>
          <label htmlFor="filter_group_name">Filter Group Name</label>
          <input type="text" name={'filter_group_name__' + index} placeholder="Enter name" onChange={this.handleNameChange(index)} value={this.state.filters[index].columnName} onKeyDown={this.handleKeyDown('filtername')} />
          <p>Typical names include Topics, Category, or Type</p>
          <div className="filter__input-group">
            {filterOption(element.numberOfOptions, index)}
          </div>
        </div>
        ))}
      </div>
    )

    const addFilterButton = (
      <button  className="add-option" onClick={this.addFilterGroup}>
        <div className="add"><FontAwesomeIcon icon={faPlus} /></div>Add Another Filter
      </button>
    )

    const removeFilterButton = (
      <div className="button-contain">
        <button  className="add-option" onClick={this.addFilterGroup}>
          <div className="add"><FontAwesomeIcon icon={faPlus} /></div>Add Another Filter
        </button>
        <button  className="add-option" onClick={this.removeFilterGroup}>
          <div className="add"><FontAwesomeIcon icon={faMinus} /></div>Remove Filter Group
        </button>
      </div>
    )

    const filterName = (
      <div className="filter__input">
        {createFilterOptions}
        {this.state.numberOfFilterGroups === 1 ? addFilterButton : removeFilterButton}
        <div className="column-submit">
          <button onClick={this.columnSubmit} className="btn--primary">Next</button>
        </div>
      </div>
    )

    const filterContent = (
      <div className="filter__inner">
        <div className="filter__enable">
          <p>Want to sort your resources?</p>
          <div className="filter__enable-container">
            <input id="enable_filter" type="checkbox" name="enable_filter" value="true" onChange={this.displayFilter} />
            <label htmlFor="enable_filter">Add filters</label>
          </div>
        </div>
        {this.state.enable_filter ? filterName : ''}
        {/* {this.state.enable_filter ? '' : <button className="btn--primary" onClick={this.skipFilters}>Skip</button> } */}
      </div>
    )

    return (
      <div className="filter">
        {filterContent}
      </div>
    )
  }
}

export default Filters;
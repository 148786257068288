import React from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iconClass: 'fa-chevron-down',
      showMenu: false,
      domain: '',
      hub_id: '',
    }
  }

  handleLogout = () => {
    this.props.logout(true);
  }

  toggleMenu = () => {
    if (this.state.showMenu) {
      this.setState({ showMenu: false, iconClass: 'fa-chevron-down' });
    } else {
      this.setState({ showMenu: true, iconClass: 'fa-chevron-up' });
    }
  }

  switchPortal = () => {
    this.props.portal();
  }


  render() {
    let userData;
    const menu = (
      <div className="header__user-menu-list">
        <div className="header__user-menu-item">
          <button onClick={this.handleLogout} className="logout-button">Logout</button>
        </div>
        {/* <div className="header__user-menu-item">
          <button onClick={this.props.portal} className="logout-button">Change Portal</button>
        </div> */}
      </div>
    );

    if (this.props.domain !== '' && this.props.hub_id !== '') {
      userData = (
        <div className="header__user-info">
          <p>Portal ID: {this.props.hub_id} <span>{this.props.domain}</span></p>
        </div>
      )
    }
  
    return (
      <header className="header header--light">
        <div className="header__inner">
          <div className="header__left">
            <Link to="/" className="header__logo header__logo--on-light">
              <svg width="297" height="50" viewBox="0 0 297 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M70.0971 4.202V19.3524C71.3275 17.155 74.3652 14.7648 78.9409 14.7648C85.3239 14.7648 89.5535 19.6993 89.5535 26.8697V35.2352C89.5535 42.4056 85.4008 47.1473 78.6718 47.1473C74.2883 47.1473 71.1737 44.8342 69.8663 42.6369V45.798C69.8663 46.2606 69.5972 46.5305 69.1358 46.5305H63.9064C63.4065 46.5305 63.0989 46.2606 63.0989 45.798V4.202C63.0989 3.8165 63.368 3.46954 63.8295 3.46954H69.3665C69.8663 3.46954 70.0971 3.8165 70.0971 4.202ZM70.0971 27.872V34.1943C70.0971 38.5891 72.9809 40.9792 76.5185 40.9792C80.479 40.9792 82.5554 38.5891 82.5554 34.1943V27.872C82.5554 23.5158 80.479 20.9329 76.5185 20.9329C72.9809 20.9329 70.0971 23.4773 70.0971 27.872Z" fill="#142341"/>
                <path d="M119.43 27.2552V34.6183C119.43 42.02 115.201 47.1473 106.203 47.1473C97.2823 47.1473 93.0142 42.02 93.0142 34.7725V27.4094C93.0142 20.0077 97.2823 14.6877 106.203 14.6877C115.201 14.7263 119.43 20.0077 119.43 27.2552ZM100.012 27.6022V34.4256C100.012 38.589 102.089 40.9792 106.203 40.9792C110.356 40.9792 112.471 38.589 112.471 34.4256V27.6022C112.471 23.4387 110.394 20.8944 106.203 20.8944C102.089 20.8558 100.012 23.4001 100.012 27.6022Z" fill="#142341"/>
                <path d="M129.889 4.202V45.7209C129.889 46.222 129.62 46.5305 129.082 46.5305H123.737C123.16 46.5305 122.891 46.2606 122.891 45.7209V4.202C122.891 3.70084 123.16 3.39244 123.737 3.39244H129.082C129.62 3.43099 129.889 3.70084 129.889 4.202Z" fill="#142341"/>
                <path d="M133.35 35.2352V26.8697C133.35 19.6993 137.58 14.7648 143.962 14.7648C148.538 14.7648 151.576 17.155 152.806 19.3524V4.202C152.806 3.8165 153.037 3.46954 153.537 3.46954H159.074C159.535 3.46954 159.805 3.8165 159.805 4.202V45.798C159.805 46.2606 159.535 46.5305 158.997 46.5305H153.768C153.306 46.5305 153.037 46.2606 153.037 45.798V42.6369C151.73 44.8342 148.654 47.1473 144.232 47.1473C137.503 47.1473 133.35 42.4056 133.35 35.2352ZM152.806 34.1943V27.872C152.806 23.4773 149.922 20.9329 146.385 20.9329C142.424 20.9329 140.348 23.5158 140.348 27.872V34.1943C140.348 38.5891 142.424 40.9792 146.385 40.9792C149.922 40.9792 152.806 38.6276 152.806 34.1943Z" fill="#142341"/>
                <path d="M187.682 22.0509C187.836 22.552 187.682 22.9375 187.105 23.1303L182.991 24.5952C182.376 24.7494 182.106 24.5952 181.914 24.017C181.03 21.4726 178.992 20.239 175.954 20.239C172.801 20.239 170.955 21.5497 170.955 23.5544C170.955 25.4819 172.263 26.2529 178.223 27.9491C185.259 29.9152 188.105 32.6908 188.105 37.6253C188.105 43.4079 183.375 47.1858 176.108 47.1858C169.571 47.1858 164.842 44.4873 163.38 39.8227C163.15 39.2059 163.38 38.8589 164.073 38.589L167.726 36.7772C168.418 36.5459 168.725 36.6615 168.956 37.4711C169.802 40.054 172.455 41.6345 175.877 41.6345C179.376 41.6345 181.414 40.2853 181.414 38.0879C181.414 36.0447 179.722 34.9653 173.878 33.3462C167.11 31.4187 164.303 28.7587 164.303 24.017C164.303 18.4271 168.879 14.6877 175.8 14.6877C181.799 14.7263 186.221 17.4248 187.682 22.0509Z" fill="#ED0E6A"/>
                <path d="M203.024 8.44256V15.5744H209.215C209.676 15.5744 209.945 15.8443 209.945 16.4225V20.5089C209.945 21.0871 209.676 21.3184 209.215 21.3184H203.024V37.0085C203.024 39.283 204.255 40.4009 206.792 40.4009H209.176C209.676 40.4009 209.984 40.6322 209.984 41.2105V45.798C209.984 46.3763 209.715 46.6076 209.176 46.6076H205.677C199.371 46.6076 196.218 43.2151 196.218 38.0108V21.2799H192.335C191.835 21.2799 191.527 21.0486 191.527 20.4703V16.4225C191.527 15.8443 191.796 15.5744 192.335 15.5744H196.295V8.44256C196.295 7.97995 196.564 7.633 197.103 7.633H202.255C202.793 7.633 203.024 7.97995 203.024 8.44256Z" fill="#ED0E6A"/>
                <path d="M238.438 24.9807V45.7209C238.438 46.2221 238.169 46.5305 237.63 46.5305H232.67C232.17 46.5305 231.863 46.2606 231.863 45.7209V42.0586C229.94 45.1812 226.787 47.1473 222.942 47.1473C217.097 47.1473 213.367 43.2922 213.367 37.7024C213.367 32.0355 217.097 28.7587 225.211 28.219C227.402 28.0648 229.479 28.0648 231.593 28.1804V25.2891C231.593 21.781 229.671 20.3547 226.479 20.3547C223.288 20.3547 222.096 21.5497 221.288 23.7471C221.058 24.3639 220.827 24.4796 220.212 24.3254L215.328 23.0918C214.829 22.9376 214.636 22.6291 214.829 22.2051C215.713 17.8103 219.75 14.7648 226.787 14.7648C234.054 14.7263 238.438 18.1573 238.438 24.9807ZM231.632 36.4688V32.6137C229.671 32.5752 227.748 32.5752 225.826 32.7294C222.173 33.1149 220.058 34.3871 220.058 37.3169C220.058 39.8612 221.788 41.4803 224.788 41.4803C227.979 41.4803 230.401 39.6299 231.632 36.4688Z" fill="#ED0E6A"/>
                <path d="M266.546 23.6315C266.584 24.1326 266.508 24.4796 265.854 24.6338L261.586 25.8288C260.74 26.0601 260.586 25.9445 260.355 25.3277C259.74 23.4773 258.817 20.8558 254.78 20.8558C250.935 20.8558 248.935 23.4002 248.935 27.5636V34.387C248.935 38.5505 250.973 40.9406 254.78 40.9406C258.433 40.9406 259.702 39.2059 260.547 36.7001C260.778 35.9676 261.047 35.852 261.739 36.1218L266.085 37.6638C266.777 37.8951 266.777 38.2806 266.661 38.8589C265.7 43.2537 261.547 47.1087 254.357 47.1087C246.513 47.1087 241.975 41.9815 241.975 34.734V27.2552C241.975 20.0077 246.551 14.7263 254.357 14.7263C261.009 14.7263 265.585 18.0416 266.546 23.6315Z" fill="#ED0E6A"/>
                <path d="M295.308 46.5305H289.386C288.809 46.5305 288.579 46.2992 288.386 45.8365L281.35 32.845L277.12 37.818V45.7209C277.12 46.222 276.851 46.5305 276.313 46.5305H270.891C270.391 46.5305 270.083 46.2606 270.083 45.7209V4.202C270.083 3.7394 270.353 3.39244 270.891 3.39244H276.313C276.813 3.39244 277.12 3.7394 277.12 4.202V29.2213L287.733 16.1141C288.233 15.5358 288.617 15.3045 289.309 15.3045H295C295.692 15.3045 295.846 15.9985 295.5 16.384L286.041 27.4094L295.923 45.451C296.192 46.0293 295.846 46.5305 295.308 46.5305Z" fill="#ED0E6A"/>
                <path d="M48.6412 50H1.23045C0.538322 50 0 49.4603 0 48.7664V1.23362C0 0.539707 0.538322 0 1.23045 0H48.6797C49.3718 0 49.9101 0.539707 49.9101 1.23362V48.8049C49.8717 49.4603 49.3333 50 48.6412 50Z" fill="#ED0E6A"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M22.3403 15.0733L23.8399 11.5266C23.9552 11.2182 23.8399 11.0255 23.4169 11.064L11.1124 11.1026C10.7663 11.1026 10.5741 11.2182 10.4203 11.4881L10.3434 11.6423L8.95912 15.0347L8.92067 15.1504C8.80532 15.4588 8.88222 15.6901 9.11293 15.9214L17.6492 24.788C17.9183 25.0964 18.149 25.0193 18.2644 24.7494L19.764 21.2028C19.8794 20.9329 19.8025 20.7016 19.5718 20.4703L14.8038 15.4973H21.6481C21.9942 15.4588 22.2249 15.3817 22.3403 15.0733ZM27.5314 34.8497L25.9934 38.3577C25.878 38.6662 25.9934 38.8589 26.3779 38.8204L38.6824 38.8975C39.0284 38.8975 39.2207 38.8204 39.3745 38.512L39.4514 38.3577L40.9126 34.9653L40.951 34.8497C41.0664 34.5413 40.9895 34.3099 40.7588 34.0786L32.2994 25.212C32.0303 24.9036 31.7995 24.9807 31.6842 25.2506L30.1461 28.7972C30.0308 29.0671 30.1077 29.2984 30.3384 29.5297L35.0679 34.5027L28.2235 34.4641C27.8775 34.4641 27.6468 34.5413 27.5314 34.8497ZM32.9528 11.064H28.8385C28.454 11.064 28.3002 11.2182 28.1464 11.5652L16.6494 38.3192C16.4956 38.7433 16.6109 38.936 16.957 38.936H21.0713C21.3789 38.936 21.5712 38.7818 21.725 38.4349L33.2604 11.6808C33.4142 11.2567 33.2989 11.064 32.9528 11.064Z" fill="white"/>
              </svg>
            </Link>
            <Link to="/" className="header__logo header__logo--on-dark">
              <svg width="297" height="50" viewBox="0 0 297 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M70.0971 4.202V19.3524C71.3275 17.155 74.3652 14.7648 78.9409 14.7648C85.3239 14.7648 89.5535 19.6993 89.5535 26.8697V35.2352C89.5535 42.4056 85.4008 47.1473 78.6718 47.1473C74.2883 47.1473 71.1737 44.8342 69.8663 42.6369V45.798C69.8663 46.2606 69.5972 46.5305 69.1358 46.5305H63.9064C63.4065 46.5305 63.0989 46.2606 63.0989 45.798V4.202C63.0989 3.8165 63.368 3.46954 63.8295 3.46954H69.3665C69.8663 3.46954 70.0971 3.8165 70.0971 4.202ZM70.0971 27.872V34.1943C70.0971 38.5891 72.9809 40.9792 76.5185 40.9792C80.479 40.9792 82.5554 38.5891 82.5554 34.1943V27.872C82.5554 23.5158 80.479 20.9329 76.5185 20.9329C72.9809 20.9329 70.0971 23.4773 70.0971 27.872Z" fill="white"/>
                <path d="M119.43 27.2552V34.6183C119.43 42.02 115.201 47.1473 106.203 47.1473C97.2823 47.1473 93.0142 42.02 93.0142 34.7725V27.4094C93.0142 20.0077 97.2823 14.6877 106.203 14.6877C115.201 14.7263 119.43 20.0077 119.43 27.2552ZM100.012 27.6022V34.4256C100.012 38.589 102.089 40.9792 106.203 40.9792C110.356 40.9792 112.471 38.589 112.471 34.4256V27.6022C112.471 23.4387 110.394 20.8944 106.203 20.8944C102.089 20.8558 100.012 23.4001 100.012 27.6022Z" fill="white"/>
                <path d="M129.889 4.202V45.7209C129.889 46.222 129.62 46.5305 129.082 46.5305H123.737C123.16 46.5305 122.891 46.2606 122.891 45.7209V4.202C122.891 3.70084 123.16 3.39244 123.737 3.39244H129.082C129.62 3.43099 129.889 3.70084 129.889 4.202Z" fill="white"/>
                <path d="M133.35 35.2352V26.8697C133.35 19.6993 137.58 14.7648 143.962 14.7648C148.538 14.7648 151.576 17.155 152.806 19.3524V4.202C152.806 3.8165 153.037 3.46954 153.537 3.46954H159.074C159.535 3.46954 159.805 3.8165 159.805 4.202V45.798C159.805 46.2606 159.535 46.5305 158.997 46.5305H153.768C153.306 46.5305 153.037 46.2606 153.037 45.798V42.6369C151.73 44.8342 148.654 47.1473 144.232 47.1473C137.503 47.1473 133.35 42.4056 133.35 35.2352ZM152.806 34.1943V27.872C152.806 23.4773 149.922 20.9329 146.385 20.9329C142.424 20.9329 140.348 23.5158 140.348 27.872V34.1943C140.348 38.5891 142.424 40.9792 146.385 40.9792C149.922 40.9792 152.806 38.6276 152.806 34.1943Z" fill="white"/>
                <path d="M187.682 22.0509C187.836 22.552 187.682 22.9375 187.105 23.1303L182.991 24.5952C182.376 24.7494 182.106 24.5952 181.914 24.017C181.03 21.4726 178.992 20.239 175.954 20.239C172.801 20.239 170.955 21.5497 170.955 23.5544C170.955 25.4819 172.263 26.2529 178.223 27.9491C185.259 29.9152 188.105 32.6908 188.105 37.6253C188.105 43.4079 183.375 47.1858 176.108 47.1858C169.571 47.1858 164.842 44.4873 163.38 39.8227C163.15 39.2059 163.38 38.8589 164.073 38.589L167.726 36.7772C168.418 36.5459 168.725 36.6615 168.956 37.4711C169.802 40.054 172.455 41.6345 175.877 41.6345C179.376 41.6345 181.414 40.2853 181.414 38.0879C181.414 36.0447 179.722 34.9653 173.878 33.3462C167.11 31.4187 164.303 28.7587 164.303 24.017C164.303 18.4271 168.879 14.6877 175.8 14.6877C181.799 14.7263 186.221 17.4248 187.682 22.0509Z" fill="white"/>
                <path d="M203.024 8.44256V15.5744H209.215C209.676 15.5744 209.945 15.8443 209.945 16.4225V20.5089C209.945 21.0871 209.676 21.3184 209.215 21.3184H203.024V37.0085C203.024 39.283 204.254 40.4009 206.792 40.4009H209.176C209.676 40.4009 209.984 40.6322 209.984 41.2105V45.798C209.984 46.3763 209.715 46.6076 209.176 46.6076H205.677C199.371 46.6076 196.218 43.2151 196.218 38.0108V21.2799H192.334C191.835 21.2799 191.527 21.0486 191.527 20.4703V16.4225C191.527 15.8443 191.796 15.5744 192.334 15.5744H196.295V8.44256C196.295 7.97995 196.564 7.633 197.102 7.633H202.255C202.793 7.633 203.024 7.97995 203.024 8.44256Z" fill="white"/>
                <path d="M238.438 24.9807V45.7209C238.438 46.2221 238.169 46.5305 237.63 46.5305H232.67C232.17 46.5305 231.863 46.2606 231.863 45.7209V42.0586C229.94 45.1812 226.787 47.1473 222.942 47.1473C217.097 47.1473 213.367 43.2922 213.367 37.7024C213.367 32.0355 217.097 28.7587 225.211 28.219C227.402 28.0648 229.479 28.0648 231.593 28.1804V25.2891C231.593 21.781 229.671 20.3547 226.479 20.3547C223.288 20.3547 222.096 21.5497 221.288 23.7471C221.058 24.3639 220.827 24.4796 220.212 24.3254L215.328 23.0918C214.829 22.9376 214.636 22.6291 214.829 22.2051C215.713 17.8103 219.75 14.7648 226.787 14.7648C234.054 14.7263 238.438 18.1573 238.438 24.9807ZM231.632 36.4688V32.6137C229.671 32.5752 227.748 32.5752 225.826 32.7294C222.173 33.1149 220.058 34.3871 220.058 37.3169C220.058 39.8612 221.788 41.4803 224.788 41.4803C227.979 41.4803 230.401 39.6299 231.632 36.4688Z" fill="white"/>
                <path d="M266.546 23.6315C266.585 24.1326 266.508 24.4796 265.854 24.6338L261.586 25.8288C260.74 26.0601 260.586 25.9445 260.355 25.3277C259.74 23.4773 258.817 20.8558 254.78 20.8558C250.935 20.8558 248.935 23.4002 248.935 27.5636V34.387C248.935 38.5505 250.973 40.9406 254.78 40.9406C258.433 40.9406 259.702 39.2059 260.548 36.7001C260.778 35.9676 261.047 35.852 261.74 36.1218L266.085 37.6638C266.777 37.8951 266.777 38.2806 266.661 38.8589C265.7 43.2537 261.547 47.1087 254.357 47.1087C246.513 47.1087 241.975 41.9815 241.975 34.734V27.2552C241.975 20.0077 246.551 14.7263 254.357 14.7263C261.009 14.7263 265.585 18.0416 266.546 23.6315Z" fill="white"/>
                <path d="M295.308 46.5305H289.386C288.809 46.5305 288.579 46.2992 288.386 45.8365L281.35 32.845L277.12 37.818V45.7209C277.12 46.222 276.851 46.5305 276.313 46.5305H270.891C270.391 46.5305 270.083 46.2606 270.083 45.7209V4.202C270.083 3.7394 270.353 3.39244 270.891 3.39244H276.313C276.813 3.39244 277.12 3.7394 277.12 4.202V29.2213L287.733 16.1141C288.233 15.5358 288.617 15.3045 289.309 15.3045H295C295.692 15.3045 295.846 15.9985 295.5 16.384L286.041 27.4094L295.923 45.451C296.192 46.0293 295.846 46.5305 295.308 46.5305Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M1.23045 50H48.6412C49.3333 50 49.8717 49.4603 49.9101 48.8049V1.23362C49.9101 0.539707 49.3718 0 48.6797 0H1.23045C0.538322 0 0 0.539707 0 1.23362V48.7664C0 49.4603 0.538322 50 1.23045 50ZM23.8399 11.5266L22.3403 15.0733C22.2249 15.3817 21.9942 15.4588 21.6481 15.4973H14.8038L19.5718 20.4703C19.8025 20.7016 19.8794 20.9329 19.764 21.2028L18.2644 24.7494C18.149 25.0193 17.9183 25.0964 17.6492 24.788L9.11293 15.9214C8.88222 15.6901 8.80532 15.4588 8.92067 15.1504L8.95912 15.0347L10.3434 11.6423L10.4203 11.4881C10.5741 11.2182 10.7663 11.1026 11.1124 11.1026L23.4169 11.064C23.8399 11.0254 23.9552 11.2182 23.8399 11.5266ZM25.9934 38.3577L27.5314 34.8497C27.6468 34.5413 27.8775 34.4641 28.2235 34.4641L35.0679 34.5027L30.3384 29.5297C30.1077 29.2984 30.0308 29.0671 30.1461 28.7972L31.6842 25.2506C31.7995 24.9807 32.0303 24.9036 32.2994 25.212L40.7588 34.0786C40.9895 34.3099 41.0664 34.5413 40.951 34.8497L40.9126 34.9653L39.4514 38.3577L39.3745 38.512C39.2207 38.8204 39.0284 38.8975 38.6824 38.8975L26.3779 38.8204C25.9934 38.8589 25.878 38.6662 25.9934 38.3577ZM28.8385 11.064H32.9528C33.2989 11.064 33.4142 11.2567 33.2604 11.6808L21.725 38.4349C21.5712 38.7818 21.3789 38.936 21.0713 38.936H16.957C16.6109 38.936 16.4956 38.7433 16.6494 38.3192L28.1464 11.5652C28.3002 11.2182 28.454 11.064 28.8385 11.064Z" fill="white"/>
              </svg>
            </Link>
          </div>
          <div className="header__center"><h2><strong>bold</strong>stack resources</h2></div>
          <div className="header__right">
            {userData}
            <div className="header__user-menu">
              <button className="header__user-menu-trigger" onClick={this.toggleMenu}>{this.props.userName} <i className={`fas ${this.state.iconClass}`}></i></button>
              {this.state.showMenu ? menu : ''}
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default Header;
import React from 'react';
import axios from 'axios';
import Authenticated from './authenticated';


class Login extends React.Component {

  state = {
    spanStyle: {
      margin: '0px 10px',
    },
    userData: '',
    user: '',
    username: '',
    firstname: '',
    lastname: '',
    showSignup: {
      display: 'none',
    },
    showLogin: {
      display: 'none',
    },
    showIntro: {
      display: 'block',
    },
    authenticated: false,
    token: '',
    refresh: '',
    expires: '',
    apiURL: 'http://localhost:8080',
    isError: false,
    errorMessage: ''
  }

  async componentDidMount() {
    let query = window.location.search;

    if (query.indexOf('?authorized=true') > -1) {
      this.setState({ showIntro: {
        display: 'none'
      }})
      query = query.split('&');
      await this.formatQuery(query);
  
      const bodyToSend = {
        token: this.state.token,
        refresh: this.state.refresh,
        expires: this.state.expires,
        user: localStorage.getItem('user'),
      }
      const updateUser = await axios.post(`/updateUser`, bodyToSend);

      if (!updateUser.data.err) {
        this.setState({ authenticated: true, isError: false, errorMessage: '' });
        window.history.replaceState({}, document.title, "/");
        window.location.reload(false);
      } else {
        this.setState({ isError: true });
      }
    }

    if (localStorage.getItem('user')) {
      await this.setState({ 
        // username: localStorage.getItem('user'),
        showIntro: {
          display: 'none',
        }
      });

      await this.checkLoginStatus();
    }
  }

  formatQuery = async (data) => {
    for (let i = 0; i < data.length; i += 1) {
      let queryString = data[i];
      queryString = queryString.split('=');
      if (queryString[0] === 'token') {
        this.setState({ token: queryString[1] });
      } else if (queryString[0] === 'refresh') {
        this.setState({ refresh: queryString[1] });
      } else if (queryString[0] === 'expires') {
        this.setState({ expires: queryString[1] });
      }
    }
  }

  handleEventChange = (event) => {
    const { target } = event;
    const { value } = target;
    const { name } = target;
    this.setState({
      [name]: value,
    });
  }

  signup = async () => {
    const user = this.state.user;
    const date = Date.now();
    const firstname = this.state.firstname;
    const lastname = this.state.lastname;

    const postBody = {
      user,
      date,
      firstname,
      lastname,
    };

    try {
      const newUser = await axios.post(`/createUser`, postBody);
      
      if (newUser.data.user) {
        // console.log(newUser.data);
        this.setState({ userData: newUser.data.user, isError: false, errorMessage: '',
          showSignup: {
            display: 'none',
          }
        });
        localStorage.setItem('user', newUser.data.user._id);
        // console.log(this.state.userData);
      } else {
        this.setState({ isError: true, errorMessage: 'Try a different username' })
      }
    } catch (err) {
      this.setState({ isError: true });
    }
  }

  checkLoginStatus = async () => {
    const date = Date.now();
    const id = localStorage.getItem('user');

    const postBody = {
      id,
      date,
    }

    try {
      const checkUser = await axios.post(`/checkStatus`, postBody);
      await this.setState({ userData: checkUser.data, isError: false, errorMessage: '' });
      localStorage.setItem('user', checkUser.data.id);
      // console.log(this.state.userData);

      if (this.state.userData.authenticated) {
        this.setState({ authenticated: true,
          showLogin: {
            display: 'none'
          }
        });
      }
    } catch (err) {
      this.setState({ isError: true,
        userData: '',
        errorMessage: 'Please refresh page or try again later',
      });
      localStorage.setItem('user', '');
    }
  }

  userLogin = async () => {
    const user = this.state.username;
    const date = Date.now();

    try {
      const getUser = await axios.get(`/getUser`, {
        params: {
          user,
          date,
        },
      });
  
      await this.setState({ userData: getUser.data, isError: false, errorMessage: '' });
      localStorage.setItem('user', getUser.data.id);
      // console.log(this.state.userData);

      if (this.state.userData.authenticated) {
        this.setState({ authenticated: true,
          showLogin: {
            display: 'none'
          }
        });
      }
    } catch (err) {
      this.setState({ isError: true,
        errorMessage: 'Invalid Username or email'
      });
    }
  }

  toggleSignupDisplay = () => {
    this.setState({ showSignup: { display: 'block'},
      showIntro: { display: 'none'}
    });
  }

  toggleLoginDisplay = () => {
    this.setState({ showLogin: { display: 'block'},
      showIntro: { display: 'none'}
    });
  }

  handleLogout = (status) => {
    if (status) {
      this.setState({ userData: '',
        authenticated: false,
        showIntro: {
          display: 'block',
        },
        username: '',
      });
    }
  }

  updateUserState = async (value) => {
    if (value) {
      await this.checkLoginStatus();
    }
  }




  render () {
    let loggedInState;
    let authenticatedState;
    let errorState;

    if (this.state.userData.username && this.state.authenticated === false) {
      loggedInState = (
        <div>
          <h5>Welcome {this.state.userData.firstname}</h5>
          <p><a href="/install">Click Here to connect Hubspot</a></p>
        </div>
      );
    }

    if (this.state.authenticated) {
      authenticatedState = (
        <div className="loggedin-area">
          <Authenticated
            userData={this.state.userData}
            logoutState={this.handleLogout}
            updateUserState={this.updateUserState}
          />
        </div>
      )
    }

    if (this.state.isError) {
      errorState = (
        <div>
          <h5>Sorry, there was an error</h5>
          <p>{ this.state.errorMessage}</p>
        </div>
      )
    }

    return (
      <div className="container">
        <h2>Hubspot App</h2>
        <div className="intro-screen" style={this.state.showIntro}>
          <h3>Welcome!</h3>
          <p><button id="login" onClick={this.toggleLoginDisplay}>Login</button><span style={this.state.spanStyle}>or</span><button id="signup" onClick={this.toggleSignupDisplay}>Sign Up</button></p>
        </div>
        <div className="signup" style={this.state.showSignup}>
          <h4>Create Account</h4>
          <div className="signup-input-holder">
            <input name="user" type="text" placeholder="Username or email" value={this.state.user} onChange={this.handleEventChange} />
            <input name="firstname" type="text" placeholder="First Name" value={this.state.firstname} onChange={this.handleEventChange} />
            <input name="lastname" type="text" placeholder="Last Name" value={this.state.lastname} onChange={this.handleEventChange} />
            <input type="Submit" id="submitNewUser" onClick={this.signup} />
          </div>
        </div>
        <div className="login" style={this.state.showLogin}>
          <h4>Login</h4>
          <div className="login-button-holder">
            <input name="username" type="text" placeholder="Username or email" value={this.state.username} onChange={this.handleEventChange} />
            <input type="Submit" id="loginUser" onClick={this.userLogin} />
          </div>
        </div>
        {loggedInState}
        {authenticatedState}
        {errorState}
      </div>
    )
  }
}

export default Login;

import React from 'react';
import axios from 'axios';
import check from 'check-types';
import CreateTable from './createTable';

class Authenticated extends React.Component {

  state = {
    userData: '',
    hubdbName: '',
    column_number: '',
    tables: '',
    errorState: '',
    tableDisplayButtonText: 'Display HubDB tables',
    contactNumberButtonText: 'Show Contact Property Groups',
    createTableButtonText: 'Create or Edit Resource Tables',
    createTable: false,
    showContacts: false,
    showTables: false,
    editTables: false,
    numberOfContacts: '',
    contactRecords: '',
    tableId: '',
    activeTable: '',
    activeTableRows: '',
    apiURL: 'http://localhost:8080',
    numberOfRows: '',
    rowStyle: {
      width: '25%',
    },
    showPropModal: false,
    propertyMatches: '',
    activeGroup: '',
    propertyGroup: '',
  }

  async componentDidMount() {
    this.setState({ 
      userData: this.props.userData
    })
  }

  handleEventChange = (event) => {
    const { target } = event;
    const { value } = target;
    const { name } = target;
    this.setState({
      [name]: value,
    });
  }

  toggleContacts = async () => {
    if (this.state.showContacts) {
      this.setState({ 
        contactNumberButtonText: 'Show Contact Property Groups',
        showContacts: false,
        errorState: '',
      });
    } else {
      const postBody = {
        id: this.state.userData.id,
      };

      try {
        const contacts = await axios.post(`/getContacts`, postBody);

        this.setState({
          showContacts: true,
          contactRecords: contacts.data.contact.results,
          contactNumberButtonText: 'Hide Contact Property Groups'
        })
      } catch (err) {
        console.log(err);
        this.setState({ errorState: 'There was an error. Try again later' });
      }
    }
  }

  toggleTables = async () => {
    if (this.state.showTables) {
      this.setState({
        tables: '',
        showTables: false,
        tableDisplayButtonText: 'Display HubDB tables',
        errorState: '',
      })
    } else {
      const bodyToSend = {
        user: this.state.userData.username,
      }
  
      try {
        const tables = await axios.post(`/getAll`, bodyToSend);
        // console.log(tables.data);
        this.setState({ tables: tables.data.tableData,
          showTables: true,
          tableDisplayButtonText: 'Hide HubDB tables'
        });
      } catch (err) {
        this.setState({ errorState: 'There was an error. Try again later'});
      }
    }
  }



  logout = () => {
    localStorage.setItem('user', '');
    this.props.logoutState(true);
  }

  updateTable = async (tableId) => {
    const tables = this.state.tables;
    const activeTable = tables.find(item => item.id === tableId);
    const tableRowData = [];
    const columns = [];


    const postBody = {
      id: this.state.userData.id,
      tableId,
    }

    const getTableData = await axios.post(`/getTableRows`, postBody);

    // Get columns for active Table
    for (let i = 0; i < activeTable.columns.length ; i += 1) {
      columns.push(activeTable.columns[i].name);
    }
    columns.push('_id');
    columns.sort();

    // format active table row data
    for (let i = 0; i < getTableData.data.rows.results.length; i += 1) {
      const rowObject = {};
      const id = getTableData.data.rows.results[i].id;
      rowObject['_id'] = id;
      Object.entries(getTableData.data.rows.results[i].values).forEach(([key, value]) => {
        rowObject[key] = value;
      });
      tableRowData.push(rowObject);
    }

    // Find rows with no data
    for (let i = 0; i < columns.length; i += 1) {
      const key = columns[i];
      for (let k = 0; k < tableRowData.length; k += 1) {
        const find = tableRowData[k][key];
        if (find === undefined) {
          tableRowData[k][key] = 'no value';
        }
      }
    }

    if (tableRowData.length === 3) {
      this.setState({ rowStyle: {
        width: '30%'
      }});
    } else if (tableRowData.length === 2) {
      this.setState({ rowStyle: {
        width: '42%',
      }});
    } else {
      this.setState({ rowStyle: {
        width: '25%',
      }})
    }

    await this.setState({ 
      editTables: true,
      tableId,
      activeTable: columns,
      activeTableRows: tableRowData,
      numberOfRows: tableRowData.length,
    });
  }

  getProperty = async (prop, name) => {
    this.setState({ propertyMatches: '', errorState: '' });
    // console.log(prop);
    const postBody = {
      id: this.state.userData.id,
      propertyName: prop,
    };

    this.setState({ showPropModal: true, activeGroup: name, propertyGroup: prop });
    try {
      const getProps = await axios.post('/getProperty', postBody);
      // console.log(getProps.data);
      this.setState({ propertyMatches: getProps.data });
    } catch (err) {
      console.log(err);
      this.setState({ errorState: 'There was an error. Try again later',
        showPropModal: false,
        activeGroup: '',
        propertyMatches: '',
      })
    }
  }

  closeModal = () => {
    this.setState({ 
      showPropModal: false,
      activeGroup: '',
      propertyMatches: '',
    });
  }

  export = async () => {
    const propertyName = this.state.propertyGroup;
    const id = this.state.userData.id;
    // console.log(propertyName);
    
    const bodyToSend = {
      id,
      propertyName,
    }

    const file = await axios.post('/export', bodyToSend);
    window.open(file.data.file);
  }

  toggleCreate = async () => {
    if (this.state.createTable) {
      this.setState({
        createTableButtonText: 'Create or edit Resource Table',
        errorState: '',
        createTable: false,
      })
    } else {
      this.setState({ showTables: false, showContacts: false, createTable: true, tables: '', createTableButtonText: 'Hide Resource Tables' });
    }
  }

  updateState = async (update) => {
    if (update) {
      await this.props.updateUserState(true);
      this.setState({ userData: this.props.userData })
    }
  }


  render () {
    let user;
    let tables = []
    let error;
    let editTableView;
    let rowData;
    let contacts;
    // let modal;
    if (this.state.userData) {
      user = (
        <div className="user-data" data-index={this.state.userData.id}>
          <p>Welcome {this.state.userData.firstname} <button className="logout" onClick={this.logout}>Logout</button></p>
        </div>
      );
    }

    if (this.state.errorState !== '') {
      error = (
        <div className="error-message">
          <p>{this.state.errorState}</p>
        </div>
      )
    }

    if (this.state.tables) {
      tables = this.state.tables.map((item, i) => (
        <div key={i} className="db_card" onClick={() => this.updateTable(item.id)}>
          <h4>Name: {item.label}</h4>
          <p>ID: {item.id}</p>
          <p>Number of columns: {item.columns.length}</p>
        </div>
      ))
    }

    if (this.state.editTables && this.state.tables) {
      editTableView = (
        <div>
          <h4>{this.state.activeTable.label}</h4>
          {/* <button>Add Row</button>
          <button>Delete Row</button> */}
          <h4>Table: {this.state.tableId}</h4>
        </div>
      )
    }

    if (this.state.activeTableRows && this.state.tables) {
      rowData = (
        <div className="row">
          {this.state.activeTableRows.map((item, i) => (
            <div className="row-data" key={'data_' + item._id + 1} style={this.state.rowStyle}>
              <div>Row {i + 1}</div>
              {Object.entries(item).map(([key, value]) => {
                if (check.array(value)) {
                  return ( <div className="row-item" key={key}><span className="bolded">{key}:</span> {value[0].name || value[0].id}</div> )
                } else if (check.object(value)) {
                  return ( <div className="row-item" key={key}><span className="bolded">{key}:</span> {value.url || value.name}</div>)
                } else {
                  return ( <div className="row-item" key={key}><span className="bolded">{key}:</span>  {value}</div>)
                }
              })}
            </div>
          ))}
      </div>)
    }

    if (this.state.showContacts) {
      contacts = (
        <div>
          <h2>Contact Property Audit</h2>
          <div>{this.state.contactRecords.map((item, i) => {
            return <p key={item.label} className="property-group" onClick={() => this.getProperty(item.name, item.label)}>{item.label}</p>
          })}</div>
        </div>
      )
    }

    const modal = (
      <div className="modal">
        <div className="modal-inner">
          <div className="close"><span className="close-modal" onClick={this.closeModal}>Close</span></div>
          <h2>{this.state.activeGroup}</h2>
          {this.state.propertyMatches !== ''
            ? this.state.propertyMatches.properties.map((item, i) => {
              return <div className="modal-match" key={item.property}>
                <div className="name">{item.property}</div>
                <div className="number">Contacts with values: {item.total}</div>
              </div>
            }) : <div>Loading... </div>
          }
          {this.state.propertyMatches !== '' ? <button onClick={this.export}>Export Properties</button> : ''}
        </div>
      </div>
    )

    const resourceTable = (
        <CreateTable 
          userData={this.state.userData}
          tableRefresh={this.updateState}
        />
    )
 

    return (
      <div className="auth-portal">
        <div className="auth-portal-info">
          <h5>You have sucessfully accessed Hubspot</h5>
          {user}
        </div>
        <div className="auth-portal-interactions">
          <div className="auth-portal-button-holder">
            {/* <button onClick={this.toggleTables}>{this.state.tableDisplayButtonText}</button> */}
            <button onClick={this.toggleCreate}>{this.state.createTableButtonText}</button>
            {/* <button onClick={this.toggleContacts}>{this.state.contactNumberButtonText}</button> */}
          </div>
          {error}
          <div className="card-holder">
            {tables}
          </div>
          {editTableView}
          {rowData}
          {contacts}
          {(this.state.createTable) ? resourceTable : ''}
          {(this.state.showPropModal) ? modal : ''}
          {(this.state.showPropModal) ? <div className="backdrop"></div> : ''}
        </div>
      </div>
    )
  }
}

export default Authenticated;

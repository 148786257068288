import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Login from './components/loginPage';
import Dashboard from './components/dashboard';
import TableSidebar from './components/tableSidebar';


import './App.css';
import './styles/bs_styles.scss';

function App() {
  return (
    <div className="dashboard">
      <Dashboard />
    </div>
  );
}

export default App;
